header {
  font-family: $sans__primary;
  font-weight: bold;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);

  @include xl {
    margin-bottom: 20px;
    position: fixed;
    width: 100%;
    z-index: 2000;
    background-color: $white;
    top: 0;
  }

  a {
    color: $blue__primary;
    text-decoration: none;
  }

  .header__top {
    background-color: $blue__primary;
    text-align: right;
    height: 30px;
    padding: 0 17px;
    display: none;

    @include xl {
      display: block;
    }

    a {
      color: $white;
      display: inline-block;
      margin: 7px 0;
      padding: 0 17px;
      text-decoration: underline;
      line-height: 1.7rem;

      &:focus {
        outline-color: white;
      }
    }
  }

  .header__bottom {
    height: 60px;
    text-align: right;
    color: $blue__primary;
    padding: 0;
    justify-content: flex-end;

    @include md {
      height: 80px;
    }

    @include xl {
      height: 90px;
      justify-content: space-between;
    }

    &>div,
    &>a {
      font-size: 1.6rem;
      line-height: 1.8rem;

      @include md {
        font-size: 2.0rem;
        line-height: 2.2rem;
      }
    }

    &__logo {
      position: absolute;
      left: 0;
      top: 0;
      padding: 15px;
      height: 60px;

      @include md {
        height: 80px;
      }

      @include xl {
        position: relative;
        float: left;
        padding: 20px;
        height: 90px;
      }

      img {
        height: 30px;

        @include md {
          height: 50px;
        } 
      }
    }

    &__nav {
      justify-content: center;


      &__item.show .dropdown-toggle i {
        transform: rotate(180deg);
        @include xl {
          transform: none;
        }
      }

      .navbar-nav>.nav-item {
        padding: 30px 8px;
        font-size: 1.8rem;
        height: 90px;

        @include at(1440) {
          padding: 30px 25px;
        }

        &.dropdown.show,
        &:hover {
          background-color: $blue__primary;

          a {
            color: $white;
          }
        }
      }

      .dropdown>a {
        display: inline-block;
      }

      .dropdown-toggle {
        padding-right: 2px;
        float: right;

        @include xl {
          float: none;
        }

        &::after {
          display: none;
        }

        i {
          font-size: 2rem;
        }
      }

      .dropdown-menu {
        background-color: $blue__primary;
        margin: 0;
        border: 0;
        box-shadow: rgba(0, 0, 0, .16) 0 13px 16px;
        border-radius: 0;

        &.active {
          display: block;
        }

        .second-level {
          @include xl {
            padding: 15px;
            display: inline;
            min-width: 24rem;

            &>a {
              border-bottom: 2px solid $blue__neon; // #3f9cd4;
              padding: .75rem 0;
              font-size: 2rem;
              font-weight: bold;
            }

            .dropdown-item:hover,
            .dropdown-item:focus {
              background-color: $blue__primary;
              text-decoration: underline;
            }
          }


          .third-level-items {
            display: none;

            @include xl {
              padding: 0;
              display: block;
              list-style: none;
            }

            .dropdown-item {
              padding: .75rem 0;
              font-size: 1.6rem;
              font-weight: normal;
            }
          }
        }

        .dropdown-item {
          color: $white;
          font-size: 1.8rem;
          padding: .75rem 1.5rem;
          white-space: normal;

          &:hover,
          &:focus {
            background-color: $blue__dark;
          }

          &>a {
            color: $white;
            padding: 0 .5rem 0 0;
            display: inline-block;
          }
        }

        &.sub-menu {
          background-color: $blue__dark;
          margin: 1rem -1.5rem 0;
          position: relative;

          .dropdown-item:hover,
          .dropdown-item:focus {
            background-color: $blue__primary;
          }
        }
      }

      .dropdown:hover .dropdown-menu,
      .dropdown.show .dropdown-menu {
        @include xl {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          flex-flow: row;
          left: -150px;
        }
      }

      &.navbar-collapse.show,
      &.navbar-collapse.collapsing {
        position: absolute;
        top: 0;
        background-color: $blue__primary;
        box-shadow: -3px 3px 6px rgba(0, 0, 0, .16);
        width: 80%;
        margin-bottom: 60px;
        z-index: 10;

        a {
          color: $white;
        }

        .navbar-nav>.nav-item {
          padding: 20px 20px 20px 50px;
          text-align: left;
          height: unset;

          &:focus,
          &:hover {
            background-color: $blue__dark;
          }
        }

        .dropdown-menu.show {
          margin-left: -50px;
          margin-right: -20px;
          padding: 0;
          background-color: $blue__dark;
          box-shadow: none;

          .dropdown-item {
            padding: 10px 10px 10px 65px;
            white-space: normal;

            &:hover,
            &:focus {
              background-color: $blue__primary;
            }
          }
        }
      }

      .mobile-links {
        text-align: left;
        padding: 100px 20px 20px 50px;
        list-style: none;

        @include xl {
          display: none;
        }

        a{
          text-decoration: underline;
          padding: 10px 0;
          display: inline-block;
          font-size: 1.4rem;
        }
      }
    }

   

    &__toggler.navbar-toggler {
      color: $blue__primary;
      border-color: transparent;

      .fa-bars {
        font-size: 2.2rem;
        line-height: 2.6rem;

        @include md {
          font-size: 2.4rem;
          line-height: 2.9rem;
        }
      }

    }

    .close-mobile-toggler {
      height: 60px;
      width: 100%;
      text-align: right;
      padding-right: 20px;
      background-color: $blue__primary;
      border-radius: 0;
      color: $white;
      box-shadow: -3px 0px 6px rgba(0, 0, 0, .16);
      font-size: 2.4rem;

      @include md {
        height: 80px;
      }
    }

    &__login {
      display: inline-block;
      padding: 19px 20px;
      height: 60px;
      background-color: $yellow;
      border: 2px solid $yellow;
      color: $blue__dark;

      &:hover {
        background-color: $yellow__light;
        border: 2px solid $yellow__light;
        color: $blue__dark;
        outline-color: $yellow__light;
      }

      &:focus,
      &:not(:disabled):not(.disabled):active {
        outline: 3px solid $yellow;
        background: $yellow;
        border: 2px solid $blue__dark;
        color: $blue__dark;
      }

      @include md {
        height: 80px;
        padding: 27px 20px;
      }

      @include xl {
        height: 90px;
        padding: 33px 25px;
      }
    }

    &.search-open {
      .header__bottom__logo {
        margin: -1000px;

        @include md {
          margin: 0;
        }
      }

      .header__bottom__toggler,
      .header__bottom__login {
        position: absolute;
        margin: -1000px;

        @include md {
          position: relative;
          margin: 0;
        }
      }

      .hbs {
        margin-right: 14px;

        @include md {
          margin-right: 0;
        }

        &__input {
          width: 255px;
          border: 0;
          height: 4rem;
          padding: 0 50px 0 2rem;
          margin: 0 -50px 0 -10px;
          border-radius: 22px;
          background-color: $blue__light;
          color: $blue__primary;

          @include md {
            margin-right: -56px;
          }

          @include lg {
            margin-right: -50px;
          }
        }

        &__activate {
          &__text {
            width: 0;
            border: 0;
            display: inline-block;
            overflow: hidden;
            transition: .5s ease all;
          }
        }

        &__close {
          display: inline-block;

          @media only screen and (max-width: 767px) {
            margin-left: 14px;
            margin-top: 5px;
          }

          @include md {
            margin-right: 0;
          }

          @include xl {
            display: none;
          }
        }
      }
    }
  }
}

.search-open .hbs {
  @media only screen and (max-width: 767px) {
    padding-right: 20px
  }
}

.routing-number
{
    display: block;
    margin: 10px 0;
    font-size: 1.4rem;

  @include xl {
    display: inline;
    margin: 0;
    font-size: 14px;
  }
}
.lity-iframe-container {
 height:95vh;
 min-height: 450px;

}