.row.padded {
    padding-top: 0;
    padding-bottom: 0;

    &:first-child {
        padding-top: 2rem;

        @include lg {
            padding-top: 4.5rem;
        }

        @include xl {
            padding-top: 7rem;
        }
    }

    &:last-child {
        padding-bottom: 3rem;

        @include lg {
            padding-bottom: 5.5rem;
        }
    
        @include xl {
            padding-bottom: 7rem;
        }
    }
}

.row.padded--heavy {
    padding-top: 0;
    padding-bottom: 0;

    &:first-child {
        padding-top: 5rem;

        @include lg {
            padding-top: 6.75rem;
        }

        @include xl {
            padding-top: 10.5rem;
        }
    }

    &:last-child {
        padding-bottom: 4.5rem;

        @include lg {
            padding-bottom: 8.25rem;
        }
    
        @include xl {
            padding-bottom: 10.5rem;
        }
    }
}

.home-background {
    background: url('/img/stock/home-background.jpg');
    background: linear-gradient(rgba($blue__overlay, 0.8), rgba($blue__overlay, 0.8)), url('/img/stock/home-background.jpg');
    background-size: cover;
    background-position: center;
}


@import "one-column-icons";
@import "three-column-icons";
@import "four-column-icons";
@import "l-fifty-fifty";
@import "dual-button-cards";
@import "current-rates-widget";
@import "recent-blog-rotator";
@import "two-column-cta";