#mainContent .blog-banner {
    h1 {
        margin: 4rem 0;
    }

    .sub-navigation {
        list-style: none;
        width: 100%;
        text-align: center;
        padding-left: 0;
        padding-top: 2rem;
        margin-bottom: 3rem;

        li {
            display: inline-block;
            font-size: 1.6rem;
            line-height: 2.2rem;
            margin-bottom: 3rem;
            padding: 0 1rem;

            @include md {
                font-size: 2.2rem;
            }

            a {
                text-decoration: none;
                padding-bottom: 8px;

                &.currentPage {
                    font-weight: bold;
                    border-bottom: 4px solid rgba(255, 255, 255, 0.7);
                }

                &:hover {
                    border-bottom: 4px solid $white;
                }
            }

            &:last-child {
                border-right: none;
            }
        }
    }
}

.sortOption {
    text-align: right;
    margin-bottom: 3rem;

    &.category {
        text-align: left;
        margin: 0;
        width: 100%;


        @include lg {
            display: none;
        }

        select {
            width: 100%;
        }

        i {
            right: 4px;
            top: -2.5rem;
            float: right;
        }
    }

    &.sort {
        float: right;
    }

    select {
        padding: .8rem;
        border: 1px solid $blue__primary;
        border-radius: 2.2rem;
        color: $blue__primary;
        width: 15rem;
        float: right;
        cursor: pointer;
        font-size: 1.6rem;
        line-height: 1.7rem;
        font-weight: bold;
        appearance: none;

        &::-ms-expand {
            display: none;
        }
    }

    i {
        position: relative;
        right: -14.7rem;
        top: 0.8rem;
        font-size: 1.8rem;
        font-weight: bold;
        pointer-events: none;
        color: $blue__primary;
    }
}

.tag-and-sort {
    clear: both;
    margin-bottom: 3rem;

    .show-more-tags-mobile {
        padding: .8rem;
        background-color: $white;
        border: 1px solid $blue__primary;
        border-radius: 2.2rem;
        color: $blue__primary;
        width: 40%;
        cursor: pointer;
        font-size: 1.6rem;
        line-height: 1.7rem;
        font-weight: bold;
        display: inline-block;

        @include at(350) {
            width: 15rem;
        }

        @include lg {
            display: none;
        }
    }
}

.blog-container {
    .filter-column {
        .filter-options {
            padding: 0;

            .filter-option {
                list-style: none;
                font-weight: bold;
                font-size: 2.2rem;
                line-height: 2.2rem;
                color: #2160A9;
                padding-bottom: 2rem;
                display: none;

                @include lg {
                    display: block;
                }

                .filterHeading {
                    display: none;

                    @include lg {
                        display: block;
                    }
                }

                ul {
                    padding-left: 0;

                    .sub-categories{
                        padding-left:15px;
                        padding-top:10px;
                    }
                }
                

                .category-filters {
                    padding-top: 2rem;
                    display: none;

                    @include lg {
                        display: block;
                    }

                    &.hidden {
                        display: none;
                        position: relative;
                        height: 180px;
                        overflow: hidden;

                        @include lg {
                            display: block;
                        }

                        &:after {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            height: 100%;
                            width: 100%;
                            content: "";
                            background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 80%);
                            pointer-events: none;
                        }
                    }


                    li {
                        font-weight: normal;
                        color: $blue__dark;
                        list-style: none;

                        a {
                            text-decoration: none;
                        }

                        .category.selected {
                            font-weight: 700;
                        }
                    }
                }

                .contributor-filters {
                    padding-top: 2rem;
                    display: none;

                    @include lg {
                        display: block;
                    }

                    li {
                        display: inline-block;
                        width: 30%;

                        @include xl {
                            width: 23%;
                        }

                        a {
                            display: block;
                        }
                    }
                }

                .tag-filters {
                    padding-top: 2rem;

                    &.hidden {
                        display: none;
                        position: relative;
                        height: 180px;
                        overflow: hidden;

                        @include lg {
                            display: block;
                        }

                        &:after {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            height: 100%;
                            width: 100%;
                            content: "";
                            background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 80%);
                            pointer-events: none;
                        }
                    }

                    li {
                        list-style: none;
                        font-weight: normal;
                        display: inline-block;
                        padding-right: 1rem;
                        font-size: 1.4rem;
                    }

                    a.selected {
                        font-weight: 700;
                    }
                }

                .show-more-tags {
                    display: none;
                    font-size: 1.6rem;
                    text-align: center;
                    font-weight: normal;
                    text-decoration: none;
                    width: 100%;
                    background: none;
                    border: none;
                    color: $blue__primary;
                    cursor: pointer;

                    @include lg {
                        display: block;
                    }
                }

                .show-more-categories {
                    display: none;
                    font-size: 1.6rem;
                    text-align: center;
                    font-weight: normal;
                    text-decoration: none;
                    width: 100%;
                    background: none;
                    border: none;
                    color: $blue__primary;
                    cursor: pointer;


                    @include lg {
                        display: block;
                    }
                }

                .show-more-categories {
                    display: none;
                    font-size: 1.6rem;
                    text-align: center;
                    font-weight: normal;
                    text-decoration: none;
                    width: 100%;
                    background: none;
                    border: none;
                    color: $blue__primary;
                    cursor: pointer;

                    @include lg {
                        display: block;
                    }
                }
            }
        }
    }

    .blogPostListing {
        .contributorInfo {
            &.hidden {
                display: none;
            }

            .contributorLink {
                color: #2160a9;
                margin-bottom: 2rem;
                display: block;
                text-align: right;
            }

            .contributorCard {
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.26);
                padding: 2rem;
                margin-bottom: 5rem;

                .contributorImage {
                    float: left;
                    margin-right: 2rem;
                }

                .contributorTitle {
                    color: #2160a9;
                    font-weight: bold;
                    font-size: 1.7rem;
                    margin-bottom: 2rem;
                }

                .contributorDiscription {
                    margin-bottom: 0;
                }
            }
        }

        // .contributorInfo ~ .blogPosts .col-md-6:first-child {
        //     // the first blog post, if a contributor is selected
        //     -ms-flex: 0 0 50%;
        //     flex: 0 0 50%;
        //     max-width: 50%;
        // }

        .blogPosts {
            clear: both;

            .blogPost {
                border: 1px solid $gray__light; //#DFE3E6;
                margin-bottom: 3rem;

                @include md {
                    min-height: 52rem;
                }

                &__text {
                    @include md {
                        overflow: hidden;
                        height: 264px;

                        &:after {
                            position: absolute;
                            bottom: 90px;
                            left: 0;
                            height: 18%;
                            width: 100%;
                            content: "";
                            background: -webkit-gradient(linear, left bottom, left top, from(white), color-stop(80%, rgba(255, 255, 255, 0)));
                            background: linear-gradient(to top, #fff 0, rgba(255, 255, 255, 0) 80%);
                        }
                    }
                }

                &__image {
                    width: 100%;
                    height: 190px;
                    background-size: cover;
                }

                &__category {
                    text-transform: uppercase;
                    color: $blue__dark;
                    font-size: 1.4rem;
                    line-height: 1.8rem;
                    margin: 2rem;
                    @include multiLineEllipsis($lineHeight: 1.8rem, $lineCount: 1, $bgColor: white);
                }

                &__heading {
                    font-size: 1.8rem;
                    font-weight: bold;
                    line-height: 2.2rem;
                    color: $blue__primary;
                    margin: 2rem;
                    display: block;
                    text-decoration: none;
                }

                &__description {
                    font-size: 1.5rem;
                    font-weight: normal;
                    line-height: 2.4rem;
                    color: $gray__dark;
                    margin: 2rem;
                }

                &__readMore {
                    font-size: 1.4rem;
                    font-weight: normal;
                    line-height: 1.8rem;
                    color: $blue__primary;
                    margin: 2rem;
                    display: block;
                    text-decoration: none;

                    i {
                        background-color: $blue__primary;
                        border-radius: 50%;
                        color: $white;
                        margin-left: 1rem;
                        padding: .3rem 0.6rem;
                    }
                }
            }

            .col-md-6:first-child {
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;

                .blogPost {
                    &__text {
                        height: auto;

                        &:after {
                            content: none;
                        }
                    }

                    &__image {
                        height: 190px;

                        @include md {
                            height: 290px;
                        }
                    }

                    &__category {
                        font-size: 1.8rem;
                    }

                    &__heading {
                        font-size: 2.2rem;
                        line-height: 3.0rem;

                        @include md {
                            font-size: 2.8rem;
                            line-height: 3.6rem;
                        }
                    }

                    &__description {
                        font-size: 1.5rem;
                        line-height: 2.4rem;
                    }

                    &__readMore {
                        font-size: 1.4rem;
                        line-height: 1.8rem;

                        i {
                            margin-left: 1rem;
                            padding: .3rem 0.6rem;
                        }
                    }
                }
            }
        }
    }
}

.contributorName {
    margin-top: 0;
    margin-bottom: 1rem;
}

.contributorTitle {
    font-size: 1.6rem;
    margin-bottom: 2rem;
}