.banner .row.padded {
  padding: 4rem 3.6rem;

  h1 {
    font-size: 2.8rem;
    text-align: center;
    margin-bottom: 0;
    margin-top: 0;

    @include md {
      font-size: 3.3rem;
    }

    @include xl {
      font-size: 5.2rem;
    }
  }
}

.searchFilters__button {
  width: 100%;
  border-radius: 2.5rem;


  @include md {
    margin-top: 0;
  }
}

.filterHeading {
  h2 {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.filterItems {
  padding-left: 0;
  margin-top: 3rem;
  margin-bottom: 0;


  &.hidden {
    display: none;

    @include lg {
      display: block
    }
  }
}

.filterItem {
  font-size: 1.8rem;
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  text-decoration: none;

  &__label {
    cursor: pointer;
    line-height: 3rem;
    vertical-align: middle
  }

  &__count {
    display: inline-block;
    background: $blue__lightTeal;
    font-size: 1.6rem;
    color: $blue__dark;
    border-radius: 2.8rem;
    min-width: 5rem;
    height: 3rem;
    line-height: 3rem;
    text-align: center;
    vertical-align: middle
  }
}

.searchResult:not(.isFaq) .searchResult__heading {
  @include multiLineEllipsis($lineHeight: 2.88rem, $lineCount: 2, $bgColor: white);
}

.searchResult {
  margin-top: 4rem;
  line-height: 1.6;

  &:first-child {
    margin-top: 2rem;
  }

  &__heading {
    font-size: 1.8rem;
    color: $blue__primary;
    margin-bottom: .8rem;

  }

  &__description {
    font-size: 1.4rem;
    line-height: 2;

    &.links-to-result {
      @include multiLineEllipsis($lineHeight: 2.8rem, $lineCount: 3, $bgColor: white);
    }

    &:before {
      content: ''
    }

    em {
      font-weight: 700;
      font-style: normal;
    }
  }
}

.search-container .row.padded {
  padding-top: 4rem;
}

.searchResultListing {
  margin-top: 2rem;

  @include md {
    margin-top: 0;
  }
}

.searchHelpText {
  h2 {
    margin-top: 0;
    margin-bottom: 0
  }

  .fas {
    color: $blue__primary;
  }
}

.paging {
  list-style: none;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-top: 6rem;
  margin-bottom: 12rem;

  @include md {
    margin-top: 9rem;
    margin-bottom: 16rem;
  }

  @include xl {
    margin-top: 12rem;
    margin-bottom: 18rem;
  }

  li {
    display: block;
    height: 4.4rem;
    width: 4.4rem;
    border-radius: .8rem;
    text-align: center;
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }

    a {
      display: block;
      line-height: 4.4rem;
      text-decoration: none;
    }
  }

  &__num,
  &__prev,
  &__next,
  &__elipses {
    cursor: pointer;
    @include noselect();
  }

  &__num,
  &__elipses {

    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .5);
  }

  &__num {
    &.active {
      background: $blue__primary;
      color: $white;

      a {

        &:link,
        &:active,
        &:visited {
          color: $white;
        }
      }
    }
  }
}

.relatedArticles {
  list-style: none;
  padding: 0;
}

.relatedArticles__item {
  margin-bottom: 2rem;
}

.sht__alsoSearchedFor {
  font-size: 1.2rem; // < the default font size is apparently smaller?
  line-height: 1.4;
  margin-top: 1.2rem;
  font-style: italic;
}

.sht__alsoSearchedFor__list {
  display: inline;
  padding-left: 0;

}

.sht__alsoSearchedFor__item {
  display: inline;
  font-size: 1.2rem;

  &:not(:first-child):before {
    content: ', ';
  }
}

.filterItem.selected {
  font-weight: 700;
}