//Colors
$blue__primary: #2160A9;
$blue__dark: #00416A;
$blue__light: #E2F6FF;
$blue__overlay:#00416a;
$gray__dark: #5F6062;
$gray__light: #E4E7E7;
$blue__neon: #4EB9E9;
$blue__lightTeal: #BEE6F7;
$white: #ffffff;
$yellow: #ffc433;
$yellow__light: #FFD466;
$orange: #F6911E;
$red: #cf342f;
$red__dark: #a92a26;
$green: #41832F;
$purple: #6d6da1;

//Fonts
$sans__primary: 'Montserrat', sans-serif;
$sans__secondary: $sans__primary;