.header__alert{
    background-color: $green;
    padding: 2rem;
    color: $white;
    font-weight: normal;
    font-size: 1.8rem;
    line-height: 2.5rem;
    text-align: center;
    margin: 0;
    p {
        color: $white;
        font-weight: normal;
        font-size: 1.8rem;
        line-height: 2.5rem;
        text-align: center;
        margin: 0;
    }
    a{
        color: $white;
        text-decoration: underline;
        font-weight: bold;
    }
}