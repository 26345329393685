.team-members{
    .team-member{
        text-align: center;
        padding-bottom: 5rem;
        cursor: pointer;

        &__name{
            font-size: 3rem;
            line-height: 3rem;
            padding-top: 1rem;
        }
        &__title{
            font-size: 1.8rem;
            line-height: 3rem;
            font-style: italic;
            padding-bottom: 1rem;
        }
        &__arrow{
            border: 20px solid transparent;
            width: 0;
            border-bottom: 20px solid $blue__dark;
            margin-left: 44%;
            display: none;
        }
        &__white-arrow{
            border: 20px solid transparent;
            width: 0;
            border-bottom: 20px solid $white;
            margin-left: 44%;
            position: relative;
            top: -37px;
            margin-bottom: -40px;
            display: none;
        }
        &__description{
            border: 1.5px solid $blue__dark;
            padding: 2rem;
            width: 100%;
            max-width: 1110px;
            display: none;
            text-align: left;
            cursor: default;

            @include lg{
                width: 330%;
            }
        }
        &.column-1 .team-member__description{
            @include lg{
                margin-left: -110%;
            }
        }
        &.column-2 .team-member__description{
            @include lg{
                margin-left: -220%;
            }
        }
        
        &.show-description{
            z-index: 500;
            .team-member__arrow, .team-member__white-arrow, .team-member__description{
                display: block;
            }
        }
    }
}