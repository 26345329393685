@charset "UTF-8";
/*-------MEDIA QUERIES-------*/
/*
 * Bootstrap has 4 breakpoints that you can use:
 *      .col-sm for larger mobile phones (devices with resolutions ≥ 576px);
 *      .col-md for tablets (≥768px);
 *      .col-lg for laptops (≥992px);
 *      .col-xl for desktops (≥1200px)
 */
/*-------A-TAG STATES-------*/
/*-------THEMES-------*/
header {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
  @media only screen and (min-width: 1200px) {
    header {
      margin-bottom: 20px;
      position: fixed;
      width: 100%;
      z-index: 2000;
      background-color: #ffffff;
      top: 0; } }
  header a {
    color: #2160A9;
    text-decoration: none; }
  header .header__top {
    background-color: #2160A9;
    text-align: right;
    height: 30px;
    padding: 0 17px;
    display: none; }
    @media only screen and (min-width: 1200px) {
      header .header__top {
        display: block; } }
    header .header__top a {
      color: #ffffff;
      display: inline-block;
      margin: 7px 0;
      padding: 0 17px;
      text-decoration: underline;
      line-height: 1.7rem; }
      header .header__top a:focus {
        outline-color: white; }
  header .header__bottom {
    height: 60px;
    text-align: right;
    color: #2160A9;
    padding: 0;
    justify-content: flex-end; }
    @media only screen and (min-width: 768px) {
      header .header__bottom {
        height: 80px; } }
    @media only screen and (min-width: 1200px) {
      header .header__bottom {
        height: 90px;
        justify-content: space-between; } }
    header .header__bottom > div,
    header .header__bottom > a {
      font-size: 1.6rem;
      line-height: 1.8rem; }
      @media only screen and (min-width: 768px) {
        header .header__bottom > div,
        header .header__bottom > a {
          font-size: 2.0rem;
          line-height: 2.2rem; } }
    header .header__bottom__logo {
      position: absolute;
      left: 0;
      top: 0;
      padding: 15px;
      height: 60px; }
      @media only screen and (min-width: 768px) {
        header .header__bottom__logo {
          height: 80px; } }
      @media only screen and (min-width: 1200px) {
        header .header__bottom__logo {
          position: relative;
          float: left;
          padding: 20px;
          height: 90px; } }
      header .header__bottom__logo img {
        height: 30px; }
        @media only screen and (min-width: 768px) {
          header .header__bottom__logo img {
            height: 50px; } }
    header .header__bottom__nav {
      justify-content: center; }
      header .header__bottom__nav__item.show .dropdown-toggle i {
        transform: rotate(180deg); }
        @media only screen and (min-width: 1200px) {
          header .header__bottom__nav__item.show .dropdown-toggle i {
            transform: none; } }
      header .header__bottom__nav .navbar-nav > .nav-item {
        padding: 30px 8px;
        font-size: 1.8rem;
        height: 90px; }
        @media only screen and (min-width: 1440px) {
          header .header__bottom__nav .navbar-nav > .nav-item {
            padding: 30px 25px; } }
        header .header__bottom__nav .navbar-nav > .nav-item.dropdown.show, header .header__bottom__nav .navbar-nav > .nav-item:hover {
          background-color: #2160A9; }
          header .header__bottom__nav .navbar-nav > .nav-item.dropdown.show a, header .header__bottom__nav .navbar-nav > .nav-item:hover a {
            color: #ffffff; }
      header .header__bottom__nav .dropdown > a {
        display: inline-block; }
      header .header__bottom__nav .dropdown-toggle {
        padding-right: 2px;
        float: right; }
        @media only screen and (min-width: 1200px) {
          header .header__bottom__nav .dropdown-toggle {
            float: none; } }
        header .header__bottom__nav .dropdown-toggle::after {
          display: none; }
        header .header__bottom__nav .dropdown-toggle i {
          font-size: 2rem; }
      header .header__bottom__nav .dropdown-menu {
        background-color: #2160A9;
        margin: 0;
        border: 0;
        box-shadow: rgba(0, 0, 0, 0.16) 0 13px 16px;
        border-radius: 0; }
        header .header__bottom__nav .dropdown-menu.active {
          display: block; }
        @media only screen and (min-width: 1200px) {
          header .header__bottom__nav .dropdown-menu .second-level {
            padding: 15px;
            display: inline;
            min-width: 24rem; }
            header .header__bottom__nav .dropdown-menu .second-level > a {
              border-bottom: 2px solid #4EB9E9;
              padding: .75rem 0;
              font-size: 2rem;
              font-weight: bold; }
            header .header__bottom__nav .dropdown-menu .second-level .dropdown-item:hover,
            header .header__bottom__nav .dropdown-menu .second-level .dropdown-item:focus {
              background-color: #2160A9;
              text-decoration: underline; } }
        header .header__bottom__nav .dropdown-menu .second-level .third-level-items {
          display: none; }
          @media only screen and (min-width: 1200px) {
            header .header__bottom__nav .dropdown-menu .second-level .third-level-items {
              padding: 0;
              display: block;
              list-style: none; } }
          header .header__bottom__nav .dropdown-menu .second-level .third-level-items .dropdown-item {
            padding: .75rem 0;
            font-size: 1.6rem;
            font-weight: normal; }
        header .header__bottom__nav .dropdown-menu .dropdown-item {
          color: #ffffff;
          font-size: 1.8rem;
          padding: .75rem 1.5rem;
          white-space: normal; }
          header .header__bottom__nav .dropdown-menu .dropdown-item:hover, header .header__bottom__nav .dropdown-menu .dropdown-item:focus {
            background-color: #00416A; }
          header .header__bottom__nav .dropdown-menu .dropdown-item > a {
            color: #ffffff;
            padding: 0 .5rem 0 0;
            display: inline-block; }
        header .header__bottom__nav .dropdown-menu.sub-menu {
          background-color: #00416A;
          margin: 1rem -1.5rem 0;
          position: relative; }
          header .header__bottom__nav .dropdown-menu.sub-menu .dropdown-item:hover,
          header .header__bottom__nav .dropdown-menu.sub-menu .dropdown-item:focus {
            background-color: #2160A9; }
      @media only screen and (min-width: 1200px) {
        header .header__bottom__nav .dropdown:hover .dropdown-menu,
        header .header__bottom__nav .dropdown.show .dropdown-menu {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          flex-flow: row;
          left: -150px; } }
      header .header__bottom__nav.navbar-collapse.show, header .header__bottom__nav.navbar-collapse.collapsing {
        position: absolute;
        top: 0;
        background-color: #2160A9;
        box-shadow: -3px 3px 6px rgba(0, 0, 0, 0.16);
        width: 80%;
        margin-bottom: 60px;
        z-index: 10; }
        header .header__bottom__nav.navbar-collapse.show a, header .header__bottom__nav.navbar-collapse.collapsing a {
          color: #ffffff; }
        header .header__bottom__nav.navbar-collapse.show .navbar-nav > .nav-item, header .header__bottom__nav.navbar-collapse.collapsing .navbar-nav > .nav-item {
          padding: 20px 20px 20px 50px;
          text-align: left;
          height: unset; }
          header .header__bottom__nav.navbar-collapse.show .navbar-nav > .nav-item:focus, header .header__bottom__nav.navbar-collapse.show .navbar-nav > .nav-item:hover, header .header__bottom__nav.navbar-collapse.collapsing .navbar-nav > .nav-item:focus, header .header__bottom__nav.navbar-collapse.collapsing .navbar-nav > .nav-item:hover {
            background-color: #00416A; }
        header .header__bottom__nav.navbar-collapse.show .dropdown-menu.show, header .header__bottom__nav.navbar-collapse.collapsing .dropdown-menu.show {
          margin-left: -50px;
          margin-right: -20px;
          padding: 0;
          background-color: #00416A;
          box-shadow: none; }
          header .header__bottom__nav.navbar-collapse.show .dropdown-menu.show .dropdown-item, header .header__bottom__nav.navbar-collapse.collapsing .dropdown-menu.show .dropdown-item {
            padding: 10px 10px 10px 65px;
            white-space: normal; }
            header .header__bottom__nav.navbar-collapse.show .dropdown-menu.show .dropdown-item:hover, header .header__bottom__nav.navbar-collapse.show .dropdown-menu.show .dropdown-item:focus, header .header__bottom__nav.navbar-collapse.collapsing .dropdown-menu.show .dropdown-item:hover, header .header__bottom__nav.navbar-collapse.collapsing .dropdown-menu.show .dropdown-item:focus {
              background-color: #2160A9; }
      header .header__bottom__nav .mobile-links {
        text-align: left;
        padding: 100px 20px 20px 50px;
        list-style: none; }
        @media only screen and (min-width: 1200px) {
          header .header__bottom__nav .mobile-links {
            display: none; } }
        header .header__bottom__nav .mobile-links a {
          text-decoration: underline;
          padding: 10px 0;
          display: inline-block;
          font-size: 1.4rem; }
    header .header__bottom__toggler.navbar-toggler {
      color: #2160A9;
      border-color: transparent; }
      header .header__bottom__toggler.navbar-toggler .fa-bars {
        font-size: 2.2rem;
        line-height: 2.6rem; }
        @media only screen and (min-width: 768px) {
          header .header__bottom__toggler.navbar-toggler .fa-bars {
            font-size: 2.4rem;
            line-height: 2.9rem; } }
    header .header__bottom .close-mobile-toggler {
      height: 60px;
      width: 100%;
      text-align: right;
      padding-right: 20px;
      background-color: #2160A9;
      border-radius: 0;
      color: #ffffff;
      box-shadow: -3px 0px 6px rgba(0, 0, 0, 0.16);
      font-size: 2.4rem; }
      @media only screen and (min-width: 768px) {
        header .header__bottom .close-mobile-toggler {
          height: 80px; } }
    header .header__bottom__login {
      display: inline-block;
      padding: 19px 20px;
      height: 60px;
      background-color: #ffc433;
      border: 2px solid #ffc433;
      color: #00416A; }
      header .header__bottom__login:hover {
        background-color: #FFD466;
        border: 2px solid #FFD466;
        color: #00416A;
        outline-color: #FFD466; }
      header .header__bottom__login:focus, header .header__bottom__login:not(:disabled):not(.disabled):active {
        outline: 3px solid #ffc433;
        background: #ffc433;
        border: 2px solid #00416A;
        color: #00416A; }
      @media only screen and (min-width: 768px) {
        header .header__bottom__login {
          height: 80px;
          padding: 27px 20px; } }
      @media only screen and (min-width: 1200px) {
        header .header__bottom__login {
          height: 90px;
          padding: 33px 25px; } }
    header .header__bottom.search-open .header__bottom__logo {
      margin: -1000px; }
      @media only screen and (min-width: 768px) {
        header .header__bottom.search-open .header__bottom__logo {
          margin: 0; } }
    header .header__bottom.search-open .header__bottom__toggler,
    header .header__bottom.search-open .header__bottom__login {
      position: absolute;
      margin: -1000px; }
      @media only screen and (min-width: 768px) {
        header .header__bottom.search-open .header__bottom__toggler,
        header .header__bottom.search-open .header__bottom__login {
          position: relative;
          margin: 0; } }
    header .header__bottom.search-open .hbs {
      margin-right: 14px; }
      @media only screen and (min-width: 768px) {
        header .header__bottom.search-open .hbs {
          margin-right: 0; } }
      header .header__bottom.search-open .hbs__input {
        width: 255px;
        border: 0;
        height: 4rem;
        padding: 0 50px 0 2rem;
        margin: 0 -50px 0 -10px;
        border-radius: 22px;
        background-color: #E2F6FF;
        color: #2160A9; }
        @media only screen and (min-width: 768px) {
          header .header__bottom.search-open .hbs__input {
            margin-right: -56px; } }
        @media only screen and (min-width: 992px) {
          header .header__bottom.search-open .hbs__input {
            margin-right: -50px; } }
      header .header__bottom.search-open .hbs__activate__text {
        width: 0;
        border: 0;
        display: inline-block;
        overflow: hidden;
        transition: .5s ease all; }
      header .header__bottom.search-open .hbs__close {
        display: inline-block; }
        @media only screen and (max-width: 767px) {
          header .header__bottom.search-open .hbs__close {
            margin-left: 14px;
            margin-top: 5px; } }
        @media only screen and (min-width: 768px) {
          header .header__bottom.search-open .hbs__close {
            margin-right: 0; } }
        @media only screen and (min-width: 1200px) {
          header .header__bottom.search-open .hbs__close {
            display: none; } }

@media only screen and (max-width: 767px) {
  .search-open .hbs {
    padding-right: 20px; } }

.routing-number {
  display: block;
  margin: 10px 0;
  font-size: 1.4rem; }
  @media only screen and (min-width: 1200px) {
    .routing-number {
      display: inline;
      margin: 0;
      font-size: 14px; } }

.lity-iframe-container {
  height: 95vh;
  min-height: 450px; }

.site-footer {
  background: #2160A9;
  color: #ffffff;
  padding: 1rem 4rem 1rem; }
  @media only screen and (min-width: 768px) {
    .site-footer {
      display: flex; } }
  @media only screen and (min-width: 2000px) {
    .site-footer {
      padding-left: 8rem;
      padding-right: 8rem; } }

.site-footer,
.site-subfooter {
  font-size: 1.4rem; }
  @media only screen and (min-width: 768px) {
    .site-footer,
    .site-subfooter {
      font-size: 1.6rem; } }
  .site-footer a,
  .site-subfooter a {
    color: #ffffff; }
    .site-footer a:focus,
    .site-subfooter a:focus {
      outline-color: white; }

@media only screen and (min-width: 768px) {
  .site-footer__links {
    order: 3; } }

@media only screen and (min-width: 2120px) {
  .site-footer__links {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; } }

.site-footer__links ul {
  padding-left: 0; }

.site-footer__links > ul > li {
  font-size: 1.7rem;
  font-weight: 700; }
  .site-footer__links > ul > li:nth-child(2n) {
    padding-right: 0; }
    @media only screen and (min-width: 768px) {
      .site-footer__links > ul > li:nth-child(2n) {
        padding-right: 15px; } }
  .site-footer__links > ul > li:nth-child(2n + 1) {
    padding-left: 0; }
    @media only screen and (min-width: 768px) {
      .site-footer__links > ul > li:nth-child(2n + 1) {
        padding-left: 15px; } }
  .site-footer__links > ul > li ul {
    margin-top: 2.4rem;
    margin-bottom: 4.4rem; }
    .site-footer__links > ul > li ul li {
      font-weight: 500;
      font-size: 1.4rem;
      margin-bottom: 1.4rem; }

.site-footer__links li {
  display: block;
  margin-top: 1rem;
  margin-bottom: 1rem; }

.site-footer__logo {
  display: block;
  max-width: 21.4rem;
  margin-top: 4rem;
  margin-bottom: 4.5rem;
  padding: 0; }
  @media only screen and (min-width: 768px) {
    .site-footer__logo {
      order: 1;
      max-width: none;
      margin-top: 2.5rem;
      padding: 0 15px; } }
  @media only screen and (min-width: 1600px) {
    .site-footer__logo {
      max-width: 75%; } }
  .site-footer__logo img {
    max-width: 100%; }
    @media only screen and (min-width: 768px) {
      .site-footer__logo img {
        max-width: 24.2rem; } }

@media only screen and (min-width: 768px) {
  .site-footer__contact {
    order: 10;
    width: 100%; }
    .site-footer__contact > div {
      display: flex;
      justify-content: center; }
      .site-footer__contact > div > p {
        margin-top: 15px;
        display: flex;
        flex-flow: row wrap; }
        .site-footer__contact > div > p span {
          margin-right: 15px; } }

.site-footer__contact__telephone, .site-footer__contact__email {
  margin-bottom: 1.2rem; }

.site-footer__social-nav {
  margin-top: 2.5rem;
  padding: 0; }
  @media only screen and (min-width: 768px) {
    .site-footer__social-nav {
      order: 4;
      margin-top: 0;
      padding-left: 1.5rem;
      padding-right: 1.5rem; } }
  @media only screen and (min-width: 2120px) {
    .site-footer__social-nav {
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%; } }
  .site-footer__social-nav ul {
    padding: 0; }
    .site-footer__social-nav ul li {
      display: inline-block;
      padding-right: 1.5rem; }
      @media only screen and (min-width: 768px) {
        .site-footer__social-nav ul li {
          padding-right: 2.5rem; } }
      @media only screen and (min-width: 768px) {
        .site-footer__social-nav ul li {
          display: block;
          margin-top: 1rem;
          margin-bottom: 1rem; } }
      .site-footer__social-nav ul li i {
        font-size: 1.8rem;
        width: 20px;
        text-align: center; }
        @media only screen and (min-width: 768px) {
          .site-footer__social-nav ul li i {
            margin-right: .8rem; } }

.site-footer__legal-images {
  order: 5;
  width: 100%;
  border-top: 1px solid white;
  padding-top: 3rem;
  text-align: center; }
  @media only screen and (min-width: 768px) {
    .site-footer__legal-images {
      border: none;
      padding-top: 0; } }
  .site-footer__legal-images .legal-image {
    margin-right: 3rem;
    display: inline-block;
    padding: 4px; }
    .site-footer__legal-images .legal-image img {
      height: 3rem; }
      @media only screen and (min-width: 768px) {
        .site-footer__legal-images .legal-image img {
          height: 4rem; } }
  .site-footer__legal-images__text {
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin-top: 3rem;
    display: inline-block; }

.site-footer__newsletter-form {
  display: none; }
  @media only screen and (min-width: 768px) {
    .site-footer__newsletter-form {
      display: block;
      order: 2;
      margin-top: 2.5rem; } }
  @media only screen and (min-width: 768px) {
    .site-footer__newsletter-form .form-group .inputs {
      display: flex; } }
  .site-footer__newsletter-form input {
    padding: .8rem 1.1rem;
    height: 4rem;
    width: 22rem;
    border-radius: 0;
    border: 0; }
    @media only screen and (min-width: 980px) {
      .site-footer__newsletter-form input {
        width: 22rem; } }
    @media only screen and (min-width: 2000px) {
      .site-footer__newsletter-form input {
        width: 28.6rem; } }
  .site-footer__newsletter-form label {
    display: block;
    margin-top: 1.2rem;
    font-size: 1.4rem; }
  .site-footer__newsletter-form button {
    height: 4rem;
    display: inline-block;
    border: none;
    margin: 0;
    text-decoration: none;
    background: #00416A;
    color: #ffffff;
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out, transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none; }
    @media only screen and (min-width: 768px) {
      .site-footer__newsletter-form button {
        padding: 1rem 3.3rem; } }
    @media only screen and (min-width: 980px) {
      .site-footer__newsletter-form button {
        padding: 1rem 3.8rem; } }
    @media only screen and (min-width: 2000px) {
      .site-footer__newsletter-form button {
        padding: 1rem 5.5rem; } }
  .site-footer__newsletter-form button:hover,
  .site-footer__newsletter-form button:focus {
    background: #0053ba; }
  .site-footer__newsletter-form button:focus {
    outline: 1px solid #fff;
    outline-offset: -4px; }
  .site-footer__newsletter-form button:active {
    transform: scale(0.99); }

.site-subfooter {
  background: #00416A;
  padding: 2.2rem 1.6rem 1rem;
  color: #ffffff;
  font-size: 1.3rem;
  line-height: 2; }
  @media only screen and (min-width: 768px) {
    .site-subfooter {
      display: flex;
      justify-content: space-between; } }
  .site-subfooter ul {
    padding: 0;
    text-align: center;
    font-size: 1.3rem; }
    .site-subfooter ul li {
      display: inline-block; }
  .site-subfooter__legal ul li:last-child:after {
    content: ''; }
  .site-subfooter__legal ul li:after {
    content: '|';
    padding-left: .3rem;
    padding-right: .3rem; }
  .site-subfooter__helpful-links {
    display: none; }
    @media only screen and (min-width: 768px) {
      .site-subfooter__helpful-links {
        display: block; } }
    .site-subfooter__helpful-links ul li {
      padding-left: 1.2rem;
      padding-right: 1.2rem; }

/*-------MEDIA QUERIES-------*/
/*
 * Bootstrap has 4 breakpoints that you can use:
 *      .col-sm for larger mobile phones (devices with resolutions ≥ 576px);
 *      .col-md for tablets (≥768px);
 *      .col-lg for laptops (≥992px);
 *      .col-xl for desktops (≥1200px)
 */
/*-------A-TAG STATES-------*/
/*-------THEMES-------*/
a {
  color: #2160A9;
  text-decoration: underline; }
  .light-text a, a.light-text {
    color: #ffffff; }

h1,
.h1 {
  font-size: 4.6rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 4.0rem;
  color: #5F6062; }
  h1:only-child,
  .h1:only-child {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .light-text h1, h1.light-text, .light-text
  .h1,
  .h1.light-text {
    color: #ffffff; }

h2,
.h2 {
  display: block;
  font-size: 2.8rem;
  color: #2160A9;
  text-transform: none;
  font-weight: 700;
  margin-top: 4rem;
  margin-bottom: 2rem; }
  h2:first-child,
  .h2:first-child {
    margin-top: 0; }
  h2:only-child,
  .h2:only-child {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  @media only screen and (min-width: 992px) {
    h2,
    .h2 {
      font-size: 3.0rem; } }
  .light-text h2, h2.light-text, .light-text
  .h2,
  .h2.light-text {
    color: #ffffff; }

h3,
.h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 2.6rem;
  display: block;
  margin-top: 3rem;
  margin-bottom: 2rem;
  color: #5F6062; }
  h3:first-child,
  .h3:first-child {
    margin-top: 0; }
  h3:only-child,
  .h3:only-child {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .light-text h3, h3.light-text, .light-text
  .h3,
  .h3.light-text {
    color: #ffffff; }

h4,
.h4 {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 2.2rem;
  margin-bottom: 1rem;
  color: #5F6062; }
  .light-text h4, h4.light-text, .light-text
  .h4,
  .h4.light-text {
    color: #ffffff; }

p {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.5;
  color: #5F6062;
  margin-bottom: 2rem; }
  .small-text p, p.small-text {
    font-size: 1.2rem;
    margin-bottom: 1.5rem; }
  .light-text p, p.light-text {
    color: #ffffff; }

ul,
ol {
  font-size: 1.6rem;
  margin-bottom: 2rem;
  line-height: 2rem; }
  ul li,
  ol li {
    color: #5F6062; }
    .light-text ul li, .light-text
    ol li {
      color: #ffffff; }

img {
  max-width: 100%; }

.btn {
  text-transform: uppercase;
  font-size: 1.4rem;
  padding: 1.2rem 3.4rem;
  margin-top: 1.4rem;
  border-radius: 0;
  min-width: 51%; }
  @media only screen and (min-width: 576px) {
    .btn {
      min-width: 0; } }
  @media only screen and (min-width: 576px) {
    .btn + .btn {
      margin-left: 2.4rem; } }

.lity {
  background: rgba(0, 0, 0, 0.6); }

.blue-primary {
  color: #2160A9; }

.blue-dark {
  color: #00416A; }

.blue-light {
  color: #E2F6FF; }

.blue-neon {
  color: #4EB9E9; }

.blue-lightTeal {
  color: #BEE6F7; }

.gray-dark {
  color: #5F6062; }

.gray-light {
  color: #E4E7E7; }

.white {
  color: #ffffff; }

.yellow {
  color: #ffc433; }

.yellow-light {
  color: #FFD466; }

.orange {
  color: #F6911E; }

.red {
  color: #cf342f; }

.red-dark {
  color: #a92a26; }

.green {
  color: #41832F; }

.purple {
  color: #6d6da1; }

.dark-background {
  background-color: #00416A; }

.light-blue-background {
  background: #BEE6F7; }
  .light-blue-background .row.padded:last-child {
    padding-bottom: 12rem; }
    @media only screen and (min-width: 768px) {
      .light-blue-background .row.padded:last-child {
        padding-bottom: 12rem; } }
  .light-blue-background .row.padded:first-child {
    padding-top: 5rem; }
    @media only screen and (min-width: 768px) {
      .light-blue-background .row.padded:first-child {
        padding-top: 12rem; } }
  .light-blue-background .btn-secondary {
    background: #BEE6F7; }
    .light-blue-background .btn-secondary:hover, .light-blue-background .btn-secondary:visited {
      color: #00416A; }

.dark-blue-text {
  color: #00416A; }
  .dark-blue-text p,
  .dark-blue-text .p {
    color: #00416A; }

a.btn-primary, button.btn-primary {
  color: #ffffff;
  background: #00416A;
  border: 2px solid #00416A; }
  a.btn-primary.yellow, button.btn-primary.yellow {
    background: #ffc433;
    border: 2px solid #ffc433;
    color: #00416A; }
    a.btn-primary.yellow:not(:disabled):not(.disabled):hover, button.btn-primary.yellow:not(:disabled):not(.disabled):hover {
      outline-color: #ffffff;
      background-color: #FFD466;
      border: 2px solid #FFD466;
      color: #00416A; }
    a.btn-primary.yellow:not([href]):not([tabindex]), button.btn-primary.yellow:not([href]):not([tabindex]) {
      color: #00416A; }
    a.btn-primary.yellow:focus, a.btn-primary.yellow:not(:disabled):not(.disabled):active, button.btn-primary.yellow:focus, button.btn-primary.yellow:not(:disabled):not(.disabled):active {
      outline: 3px solid #ffc433;
      background: #ffc433;
      border: 2px solid #00416A;
      color: #00416A; }
    a.btn-primary.yellow:disabled, a.btn-primary.yellow.disabled, button.btn-primary.yellow:disabled, button.btn-primary.yellow.disabled {
      background-color: #FFD466;
      opacity: .65;
      border: 2px solid #FFD466;
      color: #00416A; }
  a.btn-primary:hover, button.btn-primary:hover {
    background-color: #2160A9;
    border: 2px solid #2160A9; }
  a.btn-primary:focus, a.btn-primary:not(:disabled):not(.disabled):active, button.btn-primary:focus, button.btn-primary:not(:disabled):not(.disabled):active {
    outline: 3px solid #00416A;
    background: #00416A;
    border: 2px solid #ffffff; }
  a.btn-primary:disabled, a.btn-primary.disabled, button.btn-primary:disabled, button.btn-primary.disabled {
    background-color: #E4E7E7;
    border: 2px solid #E4E7E7;
    color: #00416A; }
  .light-text a.btn-primary, a.btn-primary.light-text, .light-text button.btn-primary, button.btn-primary.light-text {
    color: #00416A;
    background-color: #ffffff;
    border: 2px solid #ffffff; }
    .light-text a.btn-primary.yellow, a.btn-primary.light-text.yellow, .light-text button.btn-primary.yellow, button.btn-primary.light-text.yellow {
      background: #ffc433;
      border: 2px solid #ffc433;
      color: #00416A; }
      .light-text a.btn-primary.yellow:not(:disabled):not(.disabled):hover, a.btn-primary.light-text.yellow:not(:disabled):not(.disabled):hover, .light-text button.btn-primary.yellow:not(:disabled):not(.disabled):hover, button.btn-primary.light-text.yellow:not(:disabled):not(.disabled):hover {
        outline-color: #FFD466;
        background-color: #FFD466;
        border: 2px solid #FFD466;
        color: #00416A; }
      .light-text a.btn-primary.yellow:focus, .light-text a.btn-primary.yellow:not(:disabled):not(.disabled):active, a.btn-primary.light-text.yellow:focus, a.btn-primary.light-text.yellow:not(:disabled):not(.disabled):active, .light-text button.btn-primary.yellow:focus, .light-text button.btn-primary.yellow:not(:disabled):not(.disabled):active, button.btn-primary.light-text.yellow:focus, button.btn-primary.light-text.yellow:not(:disabled):not(.disabled):active {
        outline: 3px solid #ffc433;
        background: #ffc433;
        border: 2px solid #00416A;
        color: #00416A; }
      .light-text a.btn-primary.yellow:disabled, .light-text a.btn-primary.yellow.disabled, a.btn-primary.light-text.yellow:disabled, a.btn-primary.light-text.yellow.disabled, .light-text button.btn-primary.yellow:disabled, .light-text button.btn-primary.yellow.disabled, button.btn-primary.light-text.yellow:disabled, button.btn-primary.light-text.yellow.disabled {
        background-color: #FFD466;
        opacity: .65;
        border: 2px solid #FFD466;
        color: #00416A; }
    .light-text a.btn-primary:not(:disabled):not(.disabled):hover, a.btn-primary.light-text:not(:disabled):not(.disabled):hover, .light-text button.btn-primary:not(:disabled):not(.disabled):hover, button.btn-primary.light-text:not(:disabled):not(.disabled):hover {
      color: #ffffff;
      background-color: #2160A9;
      border: 2px solid #2160A9; }
    .light-text a.btn-primary:focus, .light-text a.btn-primary:not(:disabled):not(.disabled):active, a.btn-primary.light-text:focus, a.btn-primary.light-text:not(:disabled):not(.disabled):active, .light-text button.btn-primary:focus, .light-text button.btn-primary:not(:disabled):not(.disabled):active, button.btn-primary.light-text:focus, button.btn-primary.light-text:not(:disabled):not(.disabled):active {
      outline: 3px solid #ffffff;
      background: #ffffff;
      border: 2px solid #00416A;
      color: #00416A; }

.btn-secondary {
  color: #2160A9;
  background: #ffffff;
  border: 2px solid #2160A9; }
  .btn-secondary:hover {
    background-color: #2160A9;
    border: 2px solid #2160A9; }
  .btn-secondary:focus, .btn-secondary:not(:disabled):not(.disabled):active {
    outline: 3px solid #00416A;
    background: #00416A;
    border: 2px solid #ffffff;
    color: #ffffff; }
  .btn-secondary:disabled, .btn-secondary.disabled {
    background: #ffffff;
    border: 2px solid #5F6062;
    color: #5F6062; }
  .light-text .btn-secondary, .btn-secondary.light-text {
    color: #ffffff;
    background: #00416A;
    border: 2px solid #ffffff; }
    .light-text .btn-secondary:not(:disabled):not(.disabled):hover, .btn-secondary.light-text:not(:disabled):not(.disabled):hover {
      color: #ffffff;
      background-color: #2160A9;
      border: 2px solid #2160A9; }
    .light-text .btn-secondary:focus, .light-text .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary.light-text:focus, .btn-secondary.light-text:not(:disabled):not(.disabled):active {
      outline: 3px solid #ffffff;
      background: #ffffff;
      border: 2px solid #00416A;
      color: #00416A; }

.cursor-pointer:not(:disabled):not(.disabled) {
  cursor: pointer; }

.row.padded {
  padding-top: 0;
  padding-bottom: 0; }
  .row.padded:first-child {
    padding-top: 2rem; }
    @media only screen and (min-width: 992px) {
      .row.padded:first-child {
        padding-top: 4.5rem; } }
    @media only screen and (min-width: 1200px) {
      .row.padded:first-child {
        padding-top: 7rem; } }
  .row.padded:last-child {
    padding-bottom: 3rem; }
    @media only screen and (min-width: 992px) {
      .row.padded:last-child {
        padding-bottom: 5.5rem; } }
    @media only screen and (min-width: 1200px) {
      .row.padded:last-child {
        padding-bottom: 7rem; } }

.row.padded--heavy {
  padding-top: 0;
  padding-bottom: 0; }
  .row.padded--heavy:first-child {
    padding-top: 5rem; }
    @media only screen and (min-width: 992px) {
      .row.padded--heavy:first-child {
        padding-top: 6.75rem; } }
    @media only screen and (min-width: 1200px) {
      .row.padded--heavy:first-child {
        padding-top: 10.5rem; } }
  .row.padded--heavy:last-child {
    padding-bottom: 4.5rem; }
    @media only screen and (min-width: 992px) {
      .row.padded--heavy:last-child {
        padding-bottom: 8.25rem; } }
    @media only screen and (min-width: 1200px) {
      .row.padded--heavy:last-child {
        padding-bottom: 10.5rem; } }

.home-background {
  background: url("/img/stock/home-background.jpg");
  background: linear-gradient(rgba(0, 65, 106, 0.8), rgba(0, 65, 106, 0.8)), url("/img/stock/home-background.jpg");
  background-size: cover;
  background-position: center; }

.one-column-icons .content-placeholder,
.one-column-icons .icon-placeholder {
  display: block; }
  @media only screen and (min-width: 768px) {
    .one-column-icons .content-placeholder,
    .one-column-icons .icon-placeholder {
      display: inline-block;
      vertical-align: top; } }

@media only screen and (min-width: 768px) {
  .one-column-icons .content-placeholder {
    width: calc(100% - 5rem);
    padding-left: 1rem; } }

@media only screen and (min-width: 992px) {
  .one-column-icons .content-placeholder {
    padding-left: 2rem; } }

.one-column-icons .icon-placeholder {
  text-align: center;
  width: 100%;
  margin-top: 4rem;
  margin-bottom: 0; }
  @media only screen and (min-width: 768px) {
    .one-column-icons .icon-placeholder {
      width: auto;
      max-width: 4.4rem;
      margin-top: 0; } }
  .one-column-icons .icon-placeholder i {
    color: #2160A9;
    font-size: 10rem; }
    @media only screen and (min-width: 768px) {
      .one-column-icons .icon-placeholder i {
        font-size: 4rem;
        float: right; } }
  .one-column-icons .icon-placeholder i,
  .one-column-icons .icon-placeholder img {
    vertical-align: top; }
  .one-column-icons .icon-placeholder img {
    min-width: 10rem;
    min-height: 16rem;
    max-width: 22rem; }
    @media only screen and (min-width: 768px) {
      .one-column-icons .icon-placeholder img {
        min-width: 5rem;
        min-height: 0;
        max-width: 5.5rem;
        max-height: 6rem;
        float: right; } }

.three-column-icons .content-placeholder,
.three-column-icons .icon-placeholder {
  display: block; }
  @media only screen and (min-width: 768px) {
    .three-column-icons .content-placeholder,
    .three-column-icons .icon-placeholder {
      display: inline-block;
      vertical-align: top; } }

@media only screen and (min-width: 768px) {
  .three-column-icons .content-placeholder {
    width: calc(100% - 5rem);
    padding-left: 1rem; } }

@media only screen and (min-width: 992px) {
  .three-column-icons .content-placeholder {
    padding-left: 2rem; } }

.three-column-icons .icon-placeholder {
  text-align: center;
  width: 100%;
  margin-top: 4rem; }
  @media only screen and (min-width: 768px) {
    .three-column-icons .icon-placeholder {
      width: auto;
      max-width: 4.4rem;
      margin-top: 0; } }
  @media only screen and (min-width: 992px) {
    .three-column-icons .icon-placeholder {
      padding-top: .5rem; } }
  .three-column-icons .icon-placeholder i {
    color: #2160A9;
    font-size: 10rem; }
    @media only screen and (min-width: 768px) {
      .three-column-icons .icon-placeholder i {
        font-size: 4rem;
        float: right; } }
  .three-column-icons .icon-placeholder i,
  .three-column-icons .icon-placeholder img {
    vertical-align: middle; }
  .three-column-icons .icon-placeholder img {
    min-width: 10rem;
    min-height: 16rem;
    max-width: 22rem; }
    @media only screen and (min-width: 768px) {
      .three-column-icons .icon-placeholder img {
        min-width: 5rem;
        min-height: 0;
        max-width: 5.5rem;
        max-height: 6rem;
        float: right; } }

.four-column-icons {
  text-align: center; }
  .four-column-icons [class^="col-"] {
    margin-bottom: 2.4rem;
    margin-top: 2.4rem; }
  .four-column-icons .icon-placeholder {
    height: 12rem;
    margin-bottom: 2rem; }
    @media only screen and (min-width: 992px) {
      .four-column-icons .icon-placeholder {
        margin-bottom: 2.8rem; } }
    @media only screen and (min-width: 992px) {
      .four-column-icons .icon-placeholder {
        height: 13.3rem; } }
    .four-column-icons .icon-placeholder i {
      color: #2160A9;
      font-size: 10rem; }
    .four-column-icons .icon-placeholder img {
      min-width: 10rem;
      max-height: 12rem;
      max-width: 22rem; }

.l-fifty-fifty > .col-md-6 {
  margin-bottom: 1rem; }
  .l-fifty-fifty > .col-md-6:first-child {
    margin-top: 1rem; }
  .l-fifty-fifty > .col-md-6.image {
    order: 1; }
  .l-fifty-fifty > .col-md-6.text {
    order: 2; }
  @media only screen and (min-width: 768px) {
    .l-fifty-fifty > .col-md-6.image, .l-fifty-fifty > .col-md-6.text {
      order: unset; } }
  @media only screen and (min-width: 992px) {
    .l-fifty-fifty > .col-md-6 {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
      margin-bottom: 0; }
      .l-fifty-fifty > .col-md-6:first-child {
        margin-top: 0;
        padding-left: 1.5rem; }
      .l-fifty-fifty > .col-md-6:last-child {
        padding-right: 1.5rem; } }
  @media only screen and (min-width: 1200px) {
    .l-fifty-fifty > .col-md-6 {
      padding-left: 3.5rem;
      padding-right: 3.5rem; } }

.dual-button-card {
  padding-bottom: 7rem; }
  @media only screen and (min-width: 768px) {
    .dual-button-card {
      padding-bottom: 0; } }
  .col-md-6:last-child .dual-button-card {
    padding-bottom: 0; }

.one-fifth {
  width: 100%;
  margin-bottom: 8rem; }
  @media only screen and (min-width: 768px) {
    .one-fifth {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
      text-align: left;
      margin-bottom: 4rem; } }
  @media only screen and (min-width: 1200px) {
    .one-fifth {
      flex: 0 0 22%;
      max-width: 22%; } }

.two-fifths {
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
  display: flex; }
  @media only screen and (min-width: 1200px) {
    .two-fifths {
      flex: 0 0 34%;
      max-width: 34%; } }
  .two-fifths > div {
    align-self: center; }

.current-rates-widget {
  text-align: center; }
  .current-rates-widget .rate-icon {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center; }
    .current-rates-widget .rate-icon img {
      align-self: flex-end;
      max-height: 100%;
      max-width: 170px;
      width: 100%;
      height: 100%; }
  .current-rates-widget .rate-subtitle {
    font-size: 1.8rem;
    color: #2160A9;
    font-weight: 900;
    margin: 2rem 0 1rem; }
  .current-rates-widget .rate {
    font-size: 5.8rem;
    color: #2160A9;
    font-weight: 900;
    line-height: 5rem; }
  @media only screen and (min-width: 768px) {
    .current-rates-widget .btn {
      padding: 1.1rem 1rem;
      font-size: 1.3rem;
      width: 100%; } }

.recent-blog-posts.rondell-carousel {
  visibility: hidden; }
  .recent-blog-posts.rondell-carousel.rondell-container {
    visibility: visible;
    min-height: 35rem;
    overflow: initial;
    margin-top: 3.5rem; }
    @media only screen and (min-width: 425px) {
      .recent-blog-posts.rondell-carousel.rondell-container {
        min-height: 45rem; } }
    @media only screen and (min-width: 1200px) {
      .recent-blog-posts.rondell-carousel.rondell-container {
        margin-top: 0; } }
    .recent-blog-posts.rondell-carousel.rondell-container:not(.no-perspective) {
      perspective: 1500px;
      perspective-origin: top; }
    .recent-blog-posts.rondell-carousel.rondell-container:not(.no-transform) .tilt-left {
      transform: rotate3d(0.1, -0.3, 0, 15deg); }
    .recent-blog-posts.rondell-carousel.rondell-container:not(.no-transform) .tilt-right {
      transform: rotate3d(0.1, 0.3, 0, 15deg); }
    .recent-blog-posts.rondell-carousel.rondell-container .recentBlogPost {
      display: block;
      background-color: #E2F6FF;
      color: #00416A;
      width: 25rem;
      height: 28rem;
      padding: 1rem;
      text-decoration: none;
      cursor: pointer; }
      @media only screen and (min-width: 425px) {
        .recent-blog-posts.rondell-carousel.rondell-container .recentBlogPost {
          width: 36rem;
          height: 40rem;
          padding: 2.5rem; } }
      .recent-blog-posts.rondell-carousel.rondell-container .recentBlogPost.rondell-item-focused .recentBlogPost__image {
        height: 12rem; }
        @media only screen and (min-width: 425px) {
          .recent-blog-posts.rondell-carousel.rondell-container .recentBlogPost.rondell-item-focused .recentBlogPost__image {
            height: 17rem; } }
      .recent-blog-posts.rondell-carousel.rondell-container .recentBlogPost.rondell-item-focused .recentBlogPost__heading__text {
        overflow: hidden;
        position: relative;
        line-height: 2.1rem;
        max-height: 6.3rem;
        text-align: justify;
        padding-right: 1em; }
        .recent-blog-posts.rondell-carousel.rondell-container .recentBlogPost.rondell-item-focused .recentBlogPost__heading__text:before {
          content: '...';
          position: absolute;
          right: 0;
          bottom: 0; }
        .recent-blog-posts.rondell-carousel.rondell-container .recentBlogPost.rondell-item-focused .recentBlogPost__heading__text:after {
          content: '';
          position: absolute;
          right: 0;
          width: 1em;
          height: 1em;
          margin-top: 0.2em;
          background: #E2F6FF; }
        @media only screen and (min-width: 425px) {
          .recent-blog-posts.rondell-carousel.rondell-container .recentBlogPost.rondell-item-focused .recentBlogPost__heading__text {
            overflow: hidden;
            position: relative;
            line-height: 3rem;
            max-height: 9rem;
            text-align: justify;
            padding-right: 1em; }
            .recent-blog-posts.rondell-carousel.rondell-container .recentBlogPost.rondell-item-focused .recentBlogPost__heading__text:before {
              content: '...';
              position: absolute;
              right: 0;
              bottom: 0; }
            .recent-blog-posts.rondell-carousel.rondell-container .recentBlogPost.rondell-item-focused .recentBlogPost__heading__text:after {
              content: '';
              position: absolute;
              right: 0;
              width: 1em;
              height: 1em;
              margin-top: 0.2em;
              background: #E2F6FF; } }
      .recent-blog-posts.rondell-carousel.rondell-container .recentBlogPost.tilt-left .recentBlogPost__contributor, .recent-blog-posts.rondell-carousel.rondell-container .recentBlogPost.tilt-left .recentBlogPost__heading, .recent-blog-posts.rondell-carousel.rondell-container .recentBlogPost.tilt-left .recentBlogPost__readMore {
        display: none; }
      .recent-blog-posts.rondell-carousel.rondell-container .recentBlogPost.tilt-left .recentBlogPost__image {
        height: 26rem;
        margin-left: -20rem; }
      .recent-blog-posts.rondell-carousel.rondell-container .recentBlogPost.tilt-left .recentBlogPost__seeBlog {
        font-size: 3rem;
        line-height: 4rem;
        margin: 1rem 0;
        height: 26rem; }
      .recent-blog-posts.rondell-carousel.rondell-container .recentBlogPost.tilt-right .recentBlogPost__contributor, .recent-blog-posts.rondell-carousel.rondell-container .recentBlogPost.tilt-right .recentBlogPost__heading, .recent-blog-posts.rondell-carousel.rondell-container .recentBlogPost.tilt-right .recentBlogPost__readMore {
        display: none; }
      .recent-blog-posts.rondell-carousel.rondell-container .recentBlogPost.tilt-right .recentBlogPost__image {
        height: 26rem;
        margin-left: -20rem; }
      .recent-blog-posts.rondell-carousel.rondell-container .recentBlogPost.tilt-right .recentBlogPost__seeBlog {
        font-size: 3rem;
        line-height: 4rem;
        margin: 1rem 0;
        height: 26rem; }
      .recent-blog-posts.rondell-carousel.rondell-container .recentBlogPost__image {
        height: 12rem;
        background-size: cover;
        margin: -1rem;
        margin-bottom: 1rem; }
        @media only screen and (min-width: 425px) {
          .recent-blog-posts.rondell-carousel.rondell-container .recentBlogPost__image {
            height: 17rem;
            margin: -2.5rem;
            margin-bottom: 2.5rem; } }
      .recent-blog-posts.rondell-carousel.rondell-container .recentBlogPost__contributor {
        font-size: 1.8rem;
        line-height: 2.6rem;
        text-align: center;
        font-weight: bold;
        margin: 1rem 0; }
        @media only screen and (min-width: 425px) {
          .recent-blog-posts.rondell-carousel.rondell-container .recentBlogPost__contributor {
            margin: 2rem 0;
            font-size: 2.1rem;
            line-height: 2.9rem; } }
      .recent-blog-posts.rondell-carousel.rondell-container .recentBlogPost__heading {
        font-size: 1.6rem;
        line-height: 2.1rem;
        text-align: center;
        margin: 1rem 0;
        height: 7.8rem; }
        .recent-blog-posts.rondell-carousel.rondell-container .recentBlogPost__heading__text {
          overflow: hidden;
          position: relative;
          line-height: 2.1rem;
          max-height: 6.3rem;
          text-align: justify;
          padding-right: 1em; }
          .recent-blog-posts.rondell-carousel.rondell-container .recentBlogPost__heading__text:before {
            content: '...';
            position: absolute;
            right: 0;
            bottom: 0; }
          .recent-blog-posts.rondell-carousel.rondell-container .recentBlogPost__heading__text:after {
            content: '';
            position: absolute;
            right: 0;
            width: 1em;
            height: 1em;
            margin-top: 0.2em;
            background: #E2F6FF; }
        @media only screen and (min-width: 425px) {
          .recent-blog-posts.rondell-carousel.rondell-container .recentBlogPost__heading {
            font-size: 2rem;
            line-height: 3rem;
            height: 8.8rem; }
            .recent-blog-posts.rondell-carousel.rondell-container .recentBlogPost__heading__text {
              overflow: hidden;
              position: relative;
              line-height: 3rem;
              max-height: 9rem;
              text-align: justify;
              padding-right: 1em; }
              .recent-blog-posts.rondell-carousel.rondell-container .recentBlogPost__heading__text:before {
                content: '...';
                position: absolute;
                right: 0;
                bottom: 0; }
              .recent-blog-posts.rondell-carousel.rondell-container .recentBlogPost__heading__text:after {
                content: '';
                position: absolute;
                right: 0;
                width: 1em;
                height: 1em;
                margin-top: 0.2em;
                background: #E2F6FF; } }
      .recent-blog-posts.rondell-carousel.rondell-container .recentBlogPost__readMore {
        font-size: 1.4rem;
        line-height: 1.7rem;
        text-align: right;
        font-weight: 500;
        text-decoration: underline; }
      .recent-blog-posts.rondell-carousel.rondell-container .recentBlogPost__seeBlog {
        font-size: 3rem;
        line-height: 4rem;
        text-align: left;
        margin: 1rem 0;
        height: 21.8rem; }
        @media only screen and (min-width: 425px) {
          .recent-blog-posts.rondell-carousel.rondell-container .recentBlogPost__seeBlog {
            font-size: 4rem;
            line-height: 6rem;
            text-align: left;
            margin: 2rem 0;
            height: 28.8rem; } }
    .recent-blog-posts.rondell-carousel.rondell-container a.rondell-control {
      width: 32px;
      height: 35px;
      opacity: 1;
      background-repeat: no-repeat;
      background-position: center;
      box-shadow: none; }
      .recent-blog-posts.rondell-carousel.rondell-container a.rondell-control:hover {
        box-shadow: none; }
      .recent-blog-posts.rondell-carousel.rondell-container a.rondell-control.rondell-shift-left {
        background-image: url(../img/icons/arrowLeftBlue.png); }
      .recent-blog-posts.rondell-carousel.rondell-container a.rondell-control.rondell-shift-right {
        background-image: url(../img/icons/arrowRightBlue.png); }

.two-column-cta .col-md-6:last-child {
  justify-content: flex-end;
  display: flex; }

.two-column-cta .col-md-6 p {
  margin: 0;
  align-self: center; }

.two-column-cta .col-md-6 .btn {
  margin-top: 0;
  align-self: center; }

.row.white-card {
  flex-direction: row-reverse; }
  .row.white-card [class^="col"] {
    background: #ffffff;
    padding: 5rem 3.8rem;
    box-shadow: 0px 2px 35px 0px rgba(0, 0, 0, 0.75); }
    @media only screen and (min-width: 1200px) {
      .row.white-card [class^="col"] {
        padding: 6.2rem 4.6rem; } }
    @media only screen and (max-width: 576px) {
      .row.white-card [class^="col"] {
        background: none;
        color: #ffffff;
        box-shadow: none; }
        .row.white-card [class^="col"] h1,
        .row.white-card [class^="col"] .h1,
        .row.white-card [class^="col"] h2,
        .row.white-card [class^="col"] .h2,
        .row.white-card [class^="col"] h3,
        .row.white-card [class^="col"] .h3,
        .row.white-card [class^="col"] p,
        .row.white-card [class^="col"] .p,
        .row.white-card [class^="col"] ul,
        .row.white-card [class^="col"] a:active,
        .row.white-card [class^="col"] a:visited,
        .row.white-card [class^="col"] a:link,
        .row.white-card [class^="col"] a {
          color: #ffffff; }
          .row.white-card [class^="col"] h1.btn-primary,
          .row.white-card [class^="col"] .h1.btn-primary,
          .row.white-card [class^="col"] h2.btn-primary,
          .row.white-card [class^="col"] .h2.btn-primary,
          .row.white-card [class^="col"] h3.btn-primary,
          .row.white-card [class^="col"] .h3.btn-primary,
          .row.white-card [class^="col"] p.btn-primary,
          .row.white-card [class^="col"] .p.btn-primary,
          .row.white-card [class^="col"] ul.btn-primary,
          .row.white-card [class^="col"] a:active.btn-primary,
          .row.white-card [class^="col"] a:visited.btn-primary,
          .row.white-card [class^="col"] a:link.btn-primary,
          .row.white-card [class^="col"] a.btn-primary {
            color: #00416A; }
        .row.white-card [class^="col"] .btn {
          min-width: unset;
          margin-right: 24px; }
        .row.white-card [class^="col"] .btn-primary {
          background-color: #ffffff;
          border: 2px solid #ffffff;
          color: #00416A; }
          .row.white-card [class^="col"] .btn-primary:not(:disabled):not(.disabled):hover {
            color: #ffffff;
            background-color: #2160A9;
            border: 2px solid #2160A9; }
          .row.white-card [class^="col"] .btn-primary:focus, .row.white-card [class^="col"] .btn-primary:not(:disabled):not(.disabled):active {
            outline: 3px solid #ffffff;
            background: #ffffff;
            border: 2px solid #00416A;
            color: #00416A; }
        .row.white-card [class^="col"] .btn-secondary {
          color: #ffffff;
          background: #00416A;
          border: 2px solid #ffffff; }
          .row.white-card [class^="col"] .btn-secondary:not(:disabled):not(.disabled):hover {
            color: #ffffff;
            background-color: #2160A9;
            border: 2px solid #2160A9; }
          .row.white-card [class^="col"] .btn-secondary:focus, .row.white-card [class^="col"] .btn-secondary:not(:disabled):not(.disabled):active {
            outline: 3px solid #ffffff;
            background: #ffffff;
            border: 2px solid #00416A;
            color: #00416A; } }

.clear-card h1,
.clear-card .h1,
.clear-card h2,
.clear-card .h2,
.clear-card h3,
.clear-card .h3,
.clear-card p,
.clear-card .p,
.clear-card ul,
.clear-card a:active,
.clear-card a:visited,
.clear-card a:link,
.clear-card a {
  color: #ffffff; }

.clear-card a:active,
.clear-card a:visited,
.clear-card a:link,
.clear-card a {
  text-decoration: underline; }

.fined-card {
  background: #ffffff;
  padding: 3.4rem 2rem;
  border-radius: 1.1rem;
  text-align: center;
  margin: 4rem 0 8rem; }

@media only screen and (min-width: 768px) {
  .fined-card {
    margin: 5rem 0 4rem; } }

@media only screen and (min-width: 1200px) {
  .fined-card {
    margin: 0;
    padding: 0 4.5rem; } }

.fined-card h2 {
  font-size: 2.4rem;
  font-weight: normal; }

.fined-card .btn {
  width: 100%; }

@media only screen and (min-width: 768px) {
  .fined-card .col {
    padding: 2rem 3.6rem; } }

@media only screen and (min-width: 992px) {
  .fined-card .col {
    padding: 2rem 4rem; } }

@media only screen and (min-width: 1200px) {
  .fined-card .col {
    padding: 4rem 0; } }

.tab-sections .tab-section, .tab-sections .sf_cols {
  display: none; }
  .tab-sections .tab-section:first-child, .tab-sections .sf_cols:first-child {
    display: block; }

.tabbed-navigation ul {
  margin-bottom: 0;
  padding: 0; }

.rates-terms h2 {
  display: inline-block; }

.rates-terms .last-updated {
  font-size: 1.6rem;
  margin-top: -0.5rem;
  margin-bottom: 2rem; }
  @media only screen and (min-width: 768px) {
    .rates-terms .last-updated {
      float: right;
      margin-top: 1.5rem;
      margin-bottom: 0; } }
  .light-text .rates-terms .last-updated {
    color: #ffffff; }

table {
  width: 100%;
  margin-bottom: 3rem; }
  .light-text table {
    color: #ffffff; }
  table thead {
    left: -9999rem;
    position: absolute;
    top: -9999rem; }
    @media only screen and (min-width: 768px) {
      table thead {
        left: 0;
        position: relative;
        top: 0; }
        table thead th {
          font-size: 1.4rem;
          font-weight: bold;
          text-align: center;
          display: table-cell;
          padding: 2rem 2.6rem;
          margin: 0;
          text-transform: uppercase; }
          table thead th:first-child {
            text-align: left; } }
  table tbody td {
    display: block;
    font-size: 1.6rem;
    line-height: 1.8rem;
    margin: 2rem 2.6rem;
    text-align: left;
    font-weight: bold; }
    @media only screen and (min-width: 768px) {
      table tbody td {
        display: table-cell;
        padding: 2rem 2.6rem;
        text-align: center;
        margin: 0;
        font-weight: normal; } }
    table tbody td:first-child {
      text-align: left;
      font-weight: bold; }
    table tbody td:before {
      content: attr(name) ": ";
      display: inline-block;
      font-size: 1.6rem;
      font-weight: 300;
      margin-right: .5rem; }
      @media only screen and (min-width: 768px) {
        table tbody td:before {
          content: none; } }
  table tbody tr:nth-of-type(odd) {
    background: #e2eaf2; }
    .dark-background table tbody tr:nth-of-type(odd) {
      background: rgba(56, 113, 170, 0.5); }

form.cta-form {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto; }
  form.cta-form label {
    display: block;
    width: 100%;
    font-weight: bold; }
    .light-text form.cta-form label {
      color: white; }
  form.cta-form input {
    display: block;
    width: 100%;
    background-color: #e8f0fe;
    margin-bottom: 1.6rem;
    border: none;
    height: 4rem;
    padding: 1.3rem 1.6rem; }
    form.cta-form input[type=submit] {
      background-color: #2160A9;
      color: #ffffff;
      margin-top: 3rem;
      cursor: pointer; }

.banner .row.padded {
  padding: 4rem 3.6rem; }
  .banner .row.padded h1 {
    font-size: 2.8rem;
    text-align: center;
    margin-bottom: 0;
    margin-top: 0; }
    @media only screen and (min-width: 768px) {
      .banner .row.padded h1 {
        font-size: 3.3rem; } }
    @media only screen and (min-width: 1200px) {
      .banner .row.padded h1 {
        font-size: 5.2rem; } }

.searchFilters__button {
  width: 100%;
  border-radius: 2.5rem; }
  @media only screen and (min-width: 768px) {
    .searchFilters__button {
      margin-top: 0; } }

.filterHeading h2 {
  margin-top: 0;
  margin-bottom: 0; }

.filterItems {
  padding-left: 0;
  margin-top: 3rem;
  margin-bottom: 0; }
  .filterItems.hidden {
    display: none; }
    @media only screen and (min-width: 992px) {
      .filterItems.hidden {
        display: block; } }

.filterItem {
  font-size: 1.8rem;
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  text-decoration: none; }
  .filterItem__label {
    cursor: pointer;
    line-height: 3rem;
    vertical-align: middle; }
  .filterItem__count {
    display: inline-block;
    background: #BEE6F7;
    font-size: 1.6rem;
    color: #00416A;
    border-radius: 2.8rem;
    min-width: 5rem;
    height: 3rem;
    line-height: 3rem;
    text-align: center;
    vertical-align: middle; }

.searchResult:not(.isFaq) .searchResult__heading {
  overflow: hidden;
  position: relative;
  line-height: 2.88rem;
  max-height: 5.76rem;
  text-align: justify;
  padding-right: 1em; }
  .searchResult:not(.isFaq) .searchResult__heading:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0; }
  .searchResult:not(.isFaq) .searchResult__heading:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white; }

.searchResult {
  margin-top: 4rem;
  line-height: 1.6; }
  .searchResult:first-child {
    margin-top: 2rem; }
  .searchResult__heading {
    font-size: 1.8rem;
    color: #2160A9;
    margin-bottom: .8rem; }
  .searchResult__description {
    font-size: 1.4rem;
    line-height: 2; }
    .searchResult__description.links-to-result {
      overflow: hidden;
      position: relative;
      line-height: 2.8rem;
      max-height: 8.4rem;
      text-align: justify;
      padding-right: 1em; }
      .searchResult__description.links-to-result:before {
        content: '...';
        position: absolute;
        right: 0;
        bottom: 0; }
      .searchResult__description.links-to-result:after {
        content: '';
        position: absolute;
        right: 0;
        width: 1em;
        height: 1em;
        margin-top: 0.2em;
        background: white; }
    .searchResult__description:before {
      content: ''; }
    .searchResult__description em {
      font-weight: 700;
      font-style: normal; }

.search-container .row.padded {
  padding-top: 4rem; }

.searchResultListing {
  margin-top: 2rem; }
  @media only screen and (min-width: 768px) {
    .searchResultListing {
      margin-top: 0; } }

.searchHelpText h2 {
  margin-top: 0;
  margin-bottom: 0; }

.searchHelpText .fas {
  color: #2160A9; }

.paging {
  list-style: none;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-top: 6rem;
  margin-bottom: 12rem; }
  @media only screen and (min-width: 768px) {
    .paging {
      margin-top: 9rem;
      margin-bottom: 16rem; } }
  @media only screen and (min-width: 1200px) {
    .paging {
      margin-top: 12rem;
      margin-bottom: 18rem; } }
  .paging li {
    display: block;
    height: 4.4rem;
    width: 4.4rem;
    border-radius: .8rem;
    text-align: center;
    margin-right: 1rem; }
    .paging li:last-child {
      margin-right: 0; }
    .paging li a {
      display: block;
      line-height: 4.4rem;
      text-decoration: none; }
  .paging__num, .paging__prev, .paging__next, .paging__elipses {
    cursor: pointer;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none; }
  .paging__num, .paging__elipses {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5); }
  .paging__num.active {
    background: #2160A9;
    color: #ffffff; }
    .paging__num.active a:link, .paging__num.active a:active, .paging__num.active a:visited {
      color: #ffffff; }

.relatedArticles {
  list-style: none;
  padding: 0; }

.relatedArticles__item {
  margin-bottom: 2rem; }

.sht__alsoSearchedFor {
  font-size: 1.2rem;
  line-height: 1.4;
  margin-top: 1.2rem;
  font-style: italic; }

.sht__alsoSearchedFor__list {
  display: inline;
  padding-left: 0; }

.sht__alsoSearchedFor__item {
  display: inline;
  font-size: 1.2rem; }
  .sht__alsoSearchedFor__item:not(:first-child):before {
    content: ', '; }

.filterItem.selected {
  font-weight: 700; }

.hbs {
  display: inline-block; }
  @media only screen and (min-width: 1200px) {
    .hbs {
      height: 90px; } }
  .hbs__input {
    width: 0;
    border: 0;
    overflow: hidden;
    transition: .5s ease all;
    color: #2160A9; }
  .hbs__activate {
    background-color: transparent;
    border: 0;
    color: #2160A9;
    cursor: pointer;
    margin: 0; }
    @media only screen and (max-width: 992px) {
      .hbs__activate {
        padding-right: 4px;
        padding-top: 4px; } }
    @media only screen and (min-width: 1200px) {
      .hbs__activate {
        margin: 30px 24px 0 0; } }
    .hbs__activate__icon {
      font-size: 2.2rem;
      line-height: 2.6rem; }
      @media only screen and (min-width: 768px) {
        .hbs__activate__icon {
          font-size: 2.4rem;
          line-height: 2.9rem;
          padding-right: 1rem; } }
      @media only screen and (min-width: 1200px) {
        .hbs__activate__icon {
          font-size: 1.8rem;
          padding-top: 0; } }
    .hbs__activate__text {
      display: none;
      width: 63px;
      transition: .5s ease all; }
      @media only screen and (min-width: 1200px) {
        .hbs__activate__text {
          display: inline-block; } }
  .hbs__close {
    background: transparent;
    border: none;
    color: #2160A9;
    cursor: pointer;
    font-size: 2.1rem;
    margin-right: -5%;
    display: none; }
    @media only screen and (max-width: 767px) {
      .hbs__close {
        padding: 0; } }
  .hbs__typeahead {
    position: absolute;
    background: white;
    list-style: none;
    padding-left: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: left;
    min-width: 257px;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.25);
    z-index: 300; }
    .hbs__typeahead li {
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 12px;
      cursor: pointer; }
      .hbs__typeahead li.selected, .hbs__typeahead li:hover {
        background: #00416A;
        color: #ffffff; }

.faqFilters__button {
  width: 100%;
  border-radius: 2.5rem; }
  @media only screen and (min-width: 768px) {
    .faqFilters__button {
      margin-top: 0; } }

.faq {
  line-height: 1.6; }
  .faq.card {
    border-color: rgba(33, 96, 169, 0.3); }
  .faq:first-child {
    margin-top: 2rem; }
  .faq__question {
    font-size: 1.8rem;
    color: #2160A9; }
    .faq__question.card-header {
      background-color: #E2F6FF;
      border-bottom-color: rgba(33, 96, 169, 0.3); }
    .faq__question .btn.btn-link {
      padding: 0;
      margin: 0;
      font-size: 1.8rem;
      color: #2160A9;
      text-transform: none;
      white-space: normal;
      text-align: left; }
  .faq__answer {
    font-size: 1.4rem;
    line-height: 2; }
    .faq__answer:before {
      content: ''; }
    .faq__answer em {
      font-weight: 700;
      font-style: normal; }

.faq-container .row.padded {
  padding-top: 4rem; }

.faqListing {
  margin-top: 2rem; }
  @media only screen and (min-width: 768px) {
    .faqListing {
      margin-top: 0; } }

.paging {
  list-style: none;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-top: 6rem;
  margin-bottom: 12rem; }
  @media only screen and (min-width: 768px) {
    .paging {
      margin-top: 9rem;
      margin-bottom: 16rem; } }
  @media only screen and (min-width: 1200px) {
    .paging {
      margin-top: 12rem;
      margin-bottom: 18rem; } }
  .paging li {
    display: block;
    height: 4.4rem;
    width: 4.4rem;
    border-radius: .8rem;
    text-align: center;
    margin-right: 1rem; }
    .paging li:last-child {
      margin-right: 0; }
    .paging li a {
      display: block;
      line-height: 4.4rem;
      text-decoration: none; }
  .paging__num, .paging__prev, .paging__next, .paging__elipses {
    cursor: pointer;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none; }
  .paging__num, .paging__elipses {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5); }
  .paging__num.active {
    background: #2160A9;
    color: #ffffff; }
    .paging__num.active a:link, .paging__num.active a:active, .paging__num.active a:visited {
      color: #ffffff; }

#mainContent .blog-banner h1 {
  margin: 4rem 0; }

#mainContent .blog-banner .sub-navigation {
  list-style: none;
  width: 100%;
  text-align: center;
  padding-left: 0;
  padding-top: 2rem;
  margin-bottom: 3rem; }
  #mainContent .blog-banner .sub-navigation li {
    display: inline-block;
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin-bottom: 3rem;
    padding: 0 1rem; }
    @media only screen and (min-width: 768px) {
      #mainContent .blog-banner .sub-navigation li {
        font-size: 2.2rem; } }
    #mainContent .blog-banner .sub-navigation li a {
      text-decoration: none;
      padding-bottom: 8px; }
      #mainContent .blog-banner .sub-navigation li a.currentPage {
        font-weight: bold;
        border-bottom: 4px solid rgba(255, 255, 255, 0.7); }
      #mainContent .blog-banner .sub-navigation li a:hover {
        border-bottom: 4px solid #ffffff; }
    #mainContent .blog-banner .sub-navigation li:last-child {
      border-right: none; }

.sortOption {
  text-align: right;
  margin-bottom: 3rem; }
  .sortOption.category {
    text-align: left;
    margin: 0;
    width: 100%; }
    @media only screen and (min-width: 992px) {
      .sortOption.category {
        display: none; } }
    .sortOption.category select {
      width: 100%; }
    .sortOption.category i {
      right: 4px;
      top: -2.5rem;
      float: right; }
  .sortOption.sort {
    float: right; }
  .sortOption select {
    padding: .8rem;
    border: 1px solid #2160A9;
    border-radius: 2.2rem;
    color: #2160A9;
    width: 15rem;
    float: right;
    cursor: pointer;
    font-size: 1.6rem;
    line-height: 1.7rem;
    font-weight: bold;
    appearance: none; }
    .sortOption select::-ms-expand {
      display: none; }
  .sortOption i {
    position: relative;
    right: -14.7rem;
    top: 0.8rem;
    font-size: 1.8rem;
    font-weight: bold;
    pointer-events: none;
    color: #2160A9; }

.tag-and-sort {
  clear: both;
  margin-bottom: 3rem; }
  .tag-and-sort .show-more-tags-mobile {
    padding: .8rem;
    background-color: #ffffff;
    border: 1px solid #2160A9;
    border-radius: 2.2rem;
    color: #2160A9;
    width: 40%;
    cursor: pointer;
    font-size: 1.6rem;
    line-height: 1.7rem;
    font-weight: bold;
    display: inline-block; }
    @media only screen and (min-width: 350px) {
      .tag-and-sort .show-more-tags-mobile {
        width: 15rem; } }
    @media only screen and (min-width: 992px) {
      .tag-and-sort .show-more-tags-mobile {
        display: none; } }

.blog-container .filter-column .filter-options {
  padding: 0; }
  .blog-container .filter-column .filter-options .filter-option {
    list-style: none;
    font-weight: bold;
    font-size: 2.2rem;
    line-height: 2.2rem;
    color: #2160A9;
    padding-bottom: 2rem;
    display: none; }
    @media only screen and (min-width: 992px) {
      .blog-container .filter-column .filter-options .filter-option {
        display: block; } }
    .blog-container .filter-column .filter-options .filter-option .filterHeading {
      display: none; }
      @media only screen and (min-width: 992px) {
        .blog-container .filter-column .filter-options .filter-option .filterHeading {
          display: block; } }
    .blog-container .filter-column .filter-options .filter-option ul {
      padding-left: 0; }
      .blog-container .filter-column .filter-options .filter-option ul .sub-categories {
        padding-left: 15px;
        padding-top: 10px; }
    .blog-container .filter-column .filter-options .filter-option .category-filters {
      padding-top: 2rem;
      display: none; }
      @media only screen and (min-width: 992px) {
        .blog-container .filter-column .filter-options .filter-option .category-filters {
          display: block; } }
      .blog-container .filter-column .filter-options .filter-option .category-filters.hidden {
        display: none;
        position: relative;
        height: 180px;
        overflow: hidden; }
        @media only screen and (min-width: 992px) {
          .blog-container .filter-column .filter-options .filter-option .category-filters.hidden {
            display: block; } }
        .blog-container .filter-column .filter-options .filter-option .category-filters.hidden:after {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 100%;
          width: 100%;
          content: "";
          background: linear-gradient(to top, white 0%, rgba(255, 255, 255, 0) 80%);
          pointer-events: none; }
      .blog-container .filter-column .filter-options .filter-option .category-filters li {
        font-weight: normal;
        color: #00416A;
        list-style: none; }
        .blog-container .filter-column .filter-options .filter-option .category-filters li a {
          text-decoration: none; }
        .blog-container .filter-column .filter-options .filter-option .category-filters li .category.selected {
          font-weight: 700; }
    .blog-container .filter-column .filter-options .filter-option .contributor-filters {
      padding-top: 2rem;
      display: none; }
      @media only screen and (min-width: 992px) {
        .blog-container .filter-column .filter-options .filter-option .contributor-filters {
          display: block; } }
      .blog-container .filter-column .filter-options .filter-option .contributor-filters li {
        display: inline-block;
        width: 30%; }
        @media only screen and (min-width: 1200px) {
          .blog-container .filter-column .filter-options .filter-option .contributor-filters li {
            width: 23%; } }
        .blog-container .filter-column .filter-options .filter-option .contributor-filters li a {
          display: block; }
    .blog-container .filter-column .filter-options .filter-option .tag-filters {
      padding-top: 2rem; }
      .blog-container .filter-column .filter-options .filter-option .tag-filters.hidden {
        display: none;
        position: relative;
        height: 180px;
        overflow: hidden; }
        @media only screen and (min-width: 992px) {
          .blog-container .filter-column .filter-options .filter-option .tag-filters.hidden {
            display: block; } }
        .blog-container .filter-column .filter-options .filter-option .tag-filters.hidden:after {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 100%;
          width: 100%;
          content: "";
          background: linear-gradient(to top, white 0%, rgba(255, 255, 255, 0) 80%);
          pointer-events: none; }
      .blog-container .filter-column .filter-options .filter-option .tag-filters li {
        list-style: none;
        font-weight: normal;
        display: inline-block;
        padding-right: 1rem;
        font-size: 1.4rem; }
      .blog-container .filter-column .filter-options .filter-option .tag-filters a.selected {
        font-weight: 700; }
    .blog-container .filter-column .filter-options .filter-option .show-more-tags {
      display: none;
      font-size: 1.6rem;
      text-align: center;
      font-weight: normal;
      text-decoration: none;
      width: 100%;
      background: none;
      border: none;
      color: #2160A9;
      cursor: pointer; }
      @media only screen and (min-width: 992px) {
        .blog-container .filter-column .filter-options .filter-option .show-more-tags {
          display: block; } }
    .blog-container .filter-column .filter-options .filter-option .show-more-categories {
      display: none;
      font-size: 1.6rem;
      text-align: center;
      font-weight: normal;
      text-decoration: none;
      width: 100%;
      background: none;
      border: none;
      color: #2160A9;
      cursor: pointer; }
      @media only screen and (min-width: 992px) {
        .blog-container .filter-column .filter-options .filter-option .show-more-categories {
          display: block; } }
    .blog-container .filter-column .filter-options .filter-option .show-more-categories {
      display: none;
      font-size: 1.6rem;
      text-align: center;
      font-weight: normal;
      text-decoration: none;
      width: 100%;
      background: none;
      border: none;
      color: #2160A9;
      cursor: pointer; }
      @media only screen and (min-width: 992px) {
        .blog-container .filter-column .filter-options .filter-option .show-more-categories {
          display: block; } }

.blog-container .blogPostListing .contributorInfo.hidden {
  display: none; }

.blog-container .blogPostListing .contributorInfo .contributorLink {
  color: #2160a9;
  margin-bottom: 2rem;
  display: block;
  text-align: right; }

.blog-container .blogPostListing .contributorInfo .contributorCard {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.26);
  padding: 2rem;
  margin-bottom: 5rem; }
  .blog-container .blogPostListing .contributorInfo .contributorCard .contributorImage {
    float: left;
    margin-right: 2rem; }
  .blog-container .blogPostListing .contributorInfo .contributorCard .contributorTitle {
    color: #2160a9;
    font-weight: bold;
    font-size: 1.7rem;
    margin-bottom: 2rem; }
  .blog-container .blogPostListing .contributorInfo .contributorCard .contributorDiscription {
    margin-bottom: 0; }

.blog-container .blogPostListing .blogPosts {
  clear: both; }
  .blog-container .blogPostListing .blogPosts .blogPost {
    border: 1px solid #E4E7E7;
    margin-bottom: 3rem; }
    @media only screen and (min-width: 768px) {
      .blog-container .blogPostListing .blogPosts .blogPost {
        min-height: 52rem; } }
    @media only screen and (min-width: 768px) {
      .blog-container .blogPostListing .blogPosts .blogPost__text {
        overflow: hidden;
        height: 264px; }
        .blog-container .blogPostListing .blogPosts .blogPost__text:after {
          position: absolute;
          bottom: 90px;
          left: 0;
          height: 18%;
          width: 100%;
          content: "";
          background: -webkit-gradient(linear, left bottom, left top, from(white), color-stop(80%, rgba(255, 255, 255, 0)));
          background: linear-gradient(to top, #fff 0, rgba(255, 255, 255, 0) 80%); } }
    .blog-container .blogPostListing .blogPosts .blogPost__image {
      width: 100%;
      height: 190px;
      background-size: cover; }
    .blog-container .blogPostListing .blogPosts .blogPost__category {
      text-transform: uppercase;
      color: #00416A;
      font-size: 1.4rem;
      line-height: 1.8rem;
      margin: 2rem;
      overflow: hidden;
      position: relative;
      line-height: 1.8rem;
      max-height: 1.8rem;
      text-align: justify;
      padding-right: 1em; }
      .blog-container .blogPostListing .blogPosts .blogPost__category:before {
        content: '...';
        position: absolute;
        right: 0;
        bottom: 0; }
      .blog-container .blogPostListing .blogPosts .blogPost__category:after {
        content: '';
        position: absolute;
        right: 0;
        width: 1em;
        height: 1em;
        margin-top: 0.2em;
        background: white; }
    .blog-container .blogPostListing .blogPosts .blogPost__heading {
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 2.2rem;
      color: #2160A9;
      margin: 2rem;
      display: block;
      text-decoration: none; }
    .blog-container .blogPostListing .blogPosts .blogPost__description {
      font-size: 1.5rem;
      font-weight: normal;
      line-height: 2.4rem;
      color: #5F6062;
      margin: 2rem; }
    .blog-container .blogPostListing .blogPosts .blogPost__readMore {
      font-size: 1.4rem;
      font-weight: normal;
      line-height: 1.8rem;
      color: #2160A9;
      margin: 2rem;
      display: block;
      text-decoration: none; }
      .blog-container .blogPostListing .blogPosts .blogPost__readMore i {
        background-color: #2160A9;
        border-radius: 50%;
        color: #ffffff;
        margin-left: 1rem;
        padding: .3rem 0.6rem; }
  .blog-container .blogPostListing .blogPosts .col-md-6:first-child {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
    .blog-container .blogPostListing .blogPosts .col-md-6:first-child .blogPost__text {
      height: auto; }
      .blog-container .blogPostListing .blogPosts .col-md-6:first-child .blogPost__text:after {
        content: none; }
    .blog-container .blogPostListing .blogPosts .col-md-6:first-child .blogPost__image {
      height: 190px; }
      @media only screen and (min-width: 768px) {
        .blog-container .blogPostListing .blogPosts .col-md-6:first-child .blogPost__image {
          height: 290px; } }
    .blog-container .blogPostListing .blogPosts .col-md-6:first-child .blogPost__category {
      font-size: 1.8rem; }
    .blog-container .blogPostListing .blogPosts .col-md-6:first-child .blogPost__heading {
      font-size: 2.2rem;
      line-height: 3.0rem; }
      @media only screen and (min-width: 768px) {
        .blog-container .blogPostListing .blogPosts .col-md-6:first-child .blogPost__heading {
          font-size: 2.8rem;
          line-height: 3.6rem; } }
    .blog-container .blogPostListing .blogPosts .col-md-6:first-child .blogPost__description {
      font-size: 1.5rem;
      line-height: 2.4rem; }
    .blog-container .blogPostListing .blogPosts .col-md-6:first-child .blogPost__readMore {
      font-size: 1.4rem;
      line-height: 1.8rem; }
      .blog-container .blogPostListing .blogPosts .col-md-6:first-child .blogPost__readMore i {
        margin-left: 1rem;
        padding: .3rem 0.6rem; }

.contributorName {
  margin-top: 0;
  margin-bottom: 1rem; }

.contributorTitle {
  font-size: 1.6rem;
  margin-bottom: 2rem; }

/*-------MEDIA QUERIES-------*/
/*
 * Bootstrap has 4 breakpoints that you can use:
 *      .col-sm for larger mobile phones (devices with resolutions ≥ 576px);
 *      .col-md for tablets (≥768px);
 *      .col-lg for laptops (≥992px);
 *      .col-xl for desktops (≥1200px)
 */
/*-------A-TAG STATES-------*/
/*-------THEMES-------*/
.blog-post-container .blog-post__feature-image {
  margin-left: -15px;
  margin-right: -15px; }
  @media only screen and (min-width: 768px) {
    .blog-post-container .blog-post__feature-image {
      margin: 0; } }
  .blog-post-container .blog-post__feature-image img {
    width: 100%; }

.blog-post-container .blog-post__title {
  padding-top: 1.5rem;
  font-size: 2.4rem;
  line-height: 2.8rem;
  color: #2160A9;
  text-transform: none;
  margin-bottom: 2rem; }
  @media only screen and (min-width: 768px) {
    .blog-post-container .blog-post__title {
      font-size: 3.4rem;
      line-height: 1.3; } }
  @media only screen and (min-width: 992px) {
    .blog-post-container .blog-post__title {
      font-size: 4.8rem;
      padding-top: 2rem; } }

.blog-post-container .blog-post__body {
  margin-bottom: 110px; }

.blog-post-container .blog-post__information {
  margin-bottom: 30px; }
  @media only screen and (min-width: 768px) {
    .blog-post-container .blog-post__information {
      display: flex;
      align-items: center; } }
  .blog-post-container .blog-post__information__contributor {
    display: inline-block;
    padding-right: 3rem;
    vertical-align: middle; }
    .blog-post-container .blog-post__information__contributor__image {
      float: left;
      height: 6rem;
      width: 6rem;
      margin-right: 2rem;
      border-radius: 50%;
      overflow: hidden;
      display: inline-block;
      vertical-align: middle; }
      .blog-post-container .blog-post__information__contributor__image img {
        width: 100%;
        top: 0; }
    .blog-post-container .blog-post__information__contributor__name {
      display: inline-block;
      padding: 0 2rem 0 0;
      margin-bottom: .2rem;
      vertical-align: middle;
      font-size: 1.5rem;
      line-height: 1.8rem;
      font-weight: bold;
      color: #2160A9;
      margin-top: .2rem;
      min-width: 70%; }
      @media only screen and (min-width: 768px) {
        .blog-post-container .blog-post__information__contributor__name {
          font-size: 1.8rem;
          min-width: 0;
          display: flex;
          align-items: center; } }
  @media only screen and (min-width: 768px) {
    .blog-post-container .blog-post__information__date:before {
      content: '';
      border-right: 2px solid #BEE6F7;
      height: 6rem;
      display: block;
      margin-right: 3rem; } }
  .blog-post-container .blog-post__information__date, .blog-post-container .blog-post__information__category {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: #2160A9;
    min-width: 70%; }
    @media only screen and (min-width: 768px) {
      .blog-post-container .blog-post__information__date, .blog-post-container .blog-post__information__category {
        min-width: 0;
        display: flex;
        padding: 0 2rem;
        align-items: center;
        font-size: 1.5rem; } }
  .blog-post-container .blog-post__information__date {
    margin-bottom: .2rem; }
    @media only screen and (min-width: 1200px) {
      .blog-post-container .blog-post__information__date:before {
        content: '';
        border-right: 2px solid #BEE6F7;
        height: 6rem;
        display: block;
        margin-right: 3rem; } }
  @media only screen and (min-width: 768px) {
    .blog-post-container .blog-post__information__category:before {
      content: '';
      border-right: 2px solid #BEE6F7;
      height: 6rem;
      display: block;
      margin-right: 3rem; } }
  .blog-post-container .blog-post__information__view-more-tags {
    text-transform: uppercase; }
  .blog-post-container .blog-post__information__tags {
    display: none; }
    @media only screen and (min-width: 1200px) {
      .blog-post-container .blog-post__information__tags {
        display: flex;
        align-items: center;
        max-width: 330px;
        padding: 0 2rem; }
        .blog-post-container .blog-post__information__tags:before {
          content: '';
          border-right: 2px solid #BEE6F7;
          height: 6rem;
          display: block;
          margin-right: 3rem; }
        .blog-post-container .blog-post__information__tags__tag {
          margin-bottom: 0 !important; }
          .blog-post-container .blog-post__information__tags__tag:after {
            content: ', '; }
          .blog-post-container .blog-post__information__tags__tag:last-child:after {
            content: ''; }
        .blog-post-container .blog-post__information__tags ul {
          padding: 0;
          margin: 0;
          list-style: none; }
          .blog-post-container .blog-post__information__tags ul li {
            display: inline-block;
            line-height: 2rem; } }

.blog-post-container.financial-education-article .blog-post__information__category {
  padding-left: 0;
  max-width: 20%; }
  .blog-post-container.financial-education-article .blog-post__information__category:before {
    display: none; }

.blog-post-container.financial-education-article .blog-post__information__tags {
  max-width: 80%; }

.back-banner {
  padding: 1.5rem; }
  .back-banner .back-link {
    font-size: 1.8rem;
    text-transform: uppercase;
    color: white;
    text-decoration: none; }
    @media only screen and (min-width: 768px) {
      .back-banner .back-link {
        font-size: 2rem; } }
    .back-banner .back-link:active, .back-banner .back-link:link, .back-banner .back-link:visited {
      color: white; }
    .back-banner .back-link .fas {
      margin-right: .6rem; }

@media only screen and (min-width: 768px) {
  .float-left-50-md {
    float: left;
    max-width: 50%;
    margin-top: 2rem;
    margin-right: 1.5rem; } }

.caption {
  font-style: italic;
  margin-top: 1.5rem;
  margin-bottom: 1rem; }

.back-link-container {
  max-width: 1400px;
  margin: 0 auto; }

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.blog-post-img {
  object-fit: cover;
  min-height: 175px; }

.related-post-img {
  height: 150px;
  width: 100%;
  object-fit: cover; }

.product-comparison-widget {
  width: 100%; }
  @media only screen and (min-width: 1200px) {
    .product-comparison-widget {
      position: relative;
      padding-left: 228px; } }
  .product-comparison-widget ul {
    list-style: none;
    padding: 0; }
    .product-comparison-widget ul.feature-labels {
      display: none; }
      @media only screen and (min-width: 1200px) {
        .product-comparison-widget ul.feature-labels {
          display: inline-block;
          margin-left: -228px;
          width: 20%;
          position: absolute;
          top: 188px; } }
      .product-comparison-widget ul.feature-labels .feature-label {
        height: 40px;
        border-bottom: 1px solid #BEE6F7;
        color: #2160a9;
        font-size: 1.4rem;
        font-weight: 700;
        margin: 0;
        display: flex;
        align-items: center;
        text-align: right;
        flex-direction: row-reverse; }
        .product-comparison-widget ul.feature-labels .feature-label:first-child {
          border-top: 1px solid #BEE6F7; }
    .product-comparison-widget ul.product-cards {
      padding: 15px;
      margin-bottom: 10px; }
      @media only screen and (min-width: 1200px) {
        .product-comparison-widget ul.product-cards {
          width: 100%;
          display: inline-block; } }
      .product-comparison-widget ul.product-cards .product-card {
        border: 1px solid #E1E1E1;
        border-radius: 10px;
        padding: 35px;
        box-shadow: rgba(0, 65, 106, 0.33) 0 3px 6px;
        position: relative;
        width: 98%;
        left: 3px; }
        @media only screen and (min-width: 768px) {
          .product-comparison-widget ul.product-cards .product-card {
            width: 100%;
            left: 0; } }
        @media only screen and (min-width: 1200px) {
          .product-comparison-widget ul.product-cards .product-card {
            display: inline-block;
            margin: 0 1px;
            padding: 15px; } }
        .product-comparison-widget ul.product-cards .product-card .title {
          font-size: 2.8rem;
          color: #2160A9;
          text-align: center;
          line-height: 3.5rem;
          font-weight: bold;
          border-bottom: 1px solid #BEE6F7;
          padding: 2.6rem 5px;
          margin-bottom: 3.2rem; }
          @media only screen and (min-width: 1200px) {
            .product-comparison-widget ul.product-cards .product-card .title {
              font-size: 1.8rem;
              line-height: 3rem;
              margin: 0 -15px;
              min-height: 158px;
              display: flex;
              align-items: center; }
              .product-comparison-widget ul.product-cards .product-card .title .text {
                display: block;
                margin: 0 auto; } }
        .product-comparison-widget ul.product-cards .product-card .more-information {
          background-color: #BEE6F7;
          border: none;
          border-radius: 50%;
          color: #2160A9;
          height: 3rem;
          width: 3rem;
          position: absolute;
          top: 2rem;
          right: 2rem;
          padding: .1rem; }
          @media only screen and (min-width: 1200px) {
            .product-comparison-widget ul.product-cards .product-card .more-information {
              height: 2rem;
              width: 2rem;
              top: 1rem;
              right: 1rem; } }
          .product-comparison-widget ul.product-cards .product-card .more-information i {
            font-size: 1.6rem;
            padding: 0; }
            @media only screen and (min-width: 1200px) {
              .product-comparison-widget ul.product-cards .product-card .more-information i {
                font-size: 1rem;
                padding-bottom: 3px; } }
        .product-comparison-widget ul.product-cards .product-card ul.features li.feature {
          font-size: 1.8rem;
          font-weight: bold;
          text-align: center;
          line-height: 1.8rem;
          color: #2160A9;
          margin-bottom: 2rem; }
          @media only screen and (min-width: 1200px) {
            .product-comparison-widget ul.product-cards .product-card ul.features li.feature {
              height: 40px;
              border-bottom: 1px solid #BEE6F7;
              margin: 0 -15px;
              padding: 10px 0; } }
          .product-comparison-widget ul.product-cards .product-card ul.features li.feature.notFeatured {
            color: rgba(0, 65, 106, 0.7);
            font-weight: normal;
            text-decoration: line-through; }
            .product-comparison-widget ul.product-cards .product-card ul.features li.feature.notFeatured .offered {
              display: none; }
          .product-comparison-widget ul.product-cards .product-card ul.features li.feature.hasFeature .not-offered {
            display: none; }
          .product-comparison-widget ul.product-cards .product-card ul.features li.feature.hasFeature i:before {
            display: none; }
            @media only screen and (min-width: 1200px) {
              .product-comparison-widget ul.product-cards .product-card ul.features li.feature.hasFeature i:before {
                display: inline; } }
          @media only screen and (min-width: 1200px) {
            .product-comparison-widget ul.product-cards .product-card ul.features li.feature .feature-label {
              display: none; } }
        .product-comparison-widget ul.product-cards .product-card a.view-details {
          padding: 2rem;
          display: block;
          margin: 0 -36px -36px;
          height: 60px;
          border-radius: 0 0 10px 10px;
          font-size: 1.8rem;
          font-weight: bold;
          line-height: 1.8rem;
          text-decoration: none; }
          @media only screen and (min-width: 1200px) {
            .product-comparison-widget ul.product-cards .product-card a.view-details {
              font-size: 1.4rem;
              padding: 2rem 0;
              margin: 0 -15px -15px; } }
      @media only screen and (min-width: 1200px) {
        .product-comparison-widget ul.product-cards .owl-item:last-child .product-card {
          width: 97%; } }
      .product-comparison-widget ul.product-cards .owl-nav {
        display: none; }

.owl-dots {
  margin-top: 10px; }
  @media only screen and (min-width: 992px) {
    .owl-dots {
      display: none; } }

.owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  background-color: #ffffff;
  border: 0; }

.owl-dots .owl-dot > span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity .2s ease;
  border-radius: 30px; }
  .owl-dots .owl-dot > span span.sr-only {
    color: black;
    background: #ffffff; }

.owl-dots .owl-dot.active span,
.owl-dots .owl-dot:hover span {
  background: #869791; }

.owl-dots {
  margin-top: 40px;
  text-align: center; }
  @media only screen and (min-width: 1200px) {
    .owl-dots {
      margin-top: 10px; } }
  .owl-dots .owl-dot {
    display: inline-block; }
    .owl-dots .owl-dot span {
      display: inline-block;
      background-color: #BEE6F7;
      height: 20px;
      width: 20px;
      border: 1px solid #2160A9;
      border-radius: 50%; }
    .owl-dots .owl-dot.active span {
      background-color: #2160A9; }

.tooltip.show {
  opacity: 1; }

.tooltip .tooltip-inner {
  background-color: #ffc433;
  color: #00416A;
  font-size: 1.6rem !important; }

.tooltip.bs-tooltip-bottom .arrow:before {
  border-bottom-color: #ffc433 !important; }

.pcw__arrow-nav {
  display: none; }
  @media only screen and (min-width: 992px) {
    .pcw__arrow-nav {
      display: block;
      margin: 0 auto;
      color: #2160A9;
      width: 15%;
      font-size: 2.5rem; }
      .pcw__arrow-nav .fas.disabled {
        color: #E4E7E7; }
      .pcw__arrow-nav .fa-arrow-left {
        float: left; }
      .pcw__arrow-nav .fa-arrow-right {
        float: right; } }

.team-members .team-member {
  text-align: center;
  padding-bottom: 5rem;
  cursor: pointer; }
  .team-members .team-member__name {
    font-size: 3rem;
    line-height: 3rem;
    padding-top: 1rem; }
  .team-members .team-member__title {
    font-size: 1.8rem;
    line-height: 3rem;
    font-style: italic;
    padding-bottom: 1rem; }
  .team-members .team-member__arrow {
    border: 20px solid transparent;
    width: 0;
    border-bottom: 20px solid #00416A;
    margin-left: 44%;
    display: none; }
  .team-members .team-member__white-arrow {
    border: 20px solid transparent;
    width: 0;
    border-bottom: 20px solid #ffffff;
    margin-left: 44%;
    position: relative;
    top: -37px;
    margin-bottom: -40px;
    display: none; }
  .team-members .team-member__description {
    border: 1.5px solid #00416A;
    padding: 2rem;
    width: 100%;
    max-width: 1110px;
    display: none;
    text-align: left;
    cursor: default; }
    @media only screen and (min-width: 992px) {
      .team-members .team-member__description {
        width: 330%; } }
  @media only screen and (min-width: 992px) {
    .team-members .team-member.column-1 .team-member__description {
      margin-left: -110%; } }
  @media only screen and (min-width: 992px) {
    .team-members .team-member.column-2 .team-member__description {
      margin-left: -220%; } }
  .team-members .team-member.show-description {
    z-index: 500; }
    .team-members .team-member.show-description .team-member__arrow, .team-members .team-member.show-description .team-member__white-arrow, .team-members .team-member.show-description .team-member__description {
      display: block; }

.join-card {
  background: #ffffff;
  padding: 3.4rem 2rem;
  border-radius: 1.1rem;
  text-align: center;
  margin: 4rem 0 8rem; }
  @media only screen and (min-width: 768px) {
    .join-card {
      margin: 5rem 0 4rem; } }
  @media only screen and (min-width: 1200px) {
    .join-card {
      margin: 0;
      padding: 0 4.5rem; } }
  .join-card h2 {
    font-size: 2.4rem;
    font-weight: normal; }
  .join-card .btn {
    width: 100%; }
  @media only screen and (min-width: 768px) {
    .join-card .col {
      padding: 2rem 3.6rem; }
      .join-card .col:first-child {
        border-right: 1px solid #2160A9; } }
  @media only screen and (min-width: 992px) {
    .join-card .col {
      padding: 2rem 4rem; } }
  @media only screen and (min-width: 1200px) {
    .join-card .col {
      padding: 4rem 0; }
      .join-card .col:first-child {
        padding-top: 3rem !important;
        border-right: 0;
        border-bottom: 3px solid #2160A9; } }

@media only screen and (min-width: 768px) {
  .home-banner .clear-card {
    padding-left: 4.5em;
    padding-right: 4.5rem; } }

@media only screen and (min-width: 992px) {
  .home-banner .clear-card {
    padding-left: 5.8em;
    padding-right: 5.8rem; } }

@media only screen and (min-width: 1200px) {
  .home-banner .clear-card {
    padding-left: 15px;
    padding-right: 15px; } }

.header__alert {
  background-color: #41832F;
  padding: 2rem;
  color: #ffffff;
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 2.5rem;
  text-align: center;
  margin: 0; }
  .header__alert p {
    color: #ffffff;
    font-weight: normal;
    font-size: 1.8rem;
    line-height: 2.5rem;
    text-align: center;
    margin: 0; }
  .header__alert a {
    color: #ffffff;
    text-decoration: underline;
    font-weight: bold; }

.sfPageEditor header.header {
  z-index: initial; }

.sfTemplateEditor .header__top {
  height: auto; }

.sfTemplateEditor header {
  position: relative; }
  .sfTemplateEditor header .header__bottom {
    height: auto; }

.sfTemplateEditor .navbar {
  display: block; }

.sfTemplateEditor header .header__bottom__logo {
  float: none;
  height: auto; }

.sfTemplateEditor .header__bottom__login {
  height: auto; }

.sfTemplateEditor .site-footer__contact {
  display: block !important; }

:root {
  font-size: 62.5%; }

body {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  font-size: 1.4rem;
  line-height: 1.5rem; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

i.fas {
  padding: 0 .4rem; }

#mainContent li {
  margin-bottom: 1.5rem;
  line-height: 2.3rem; }

@media only screen and (min-width: 1200px) {
  #mainContent {
    margin-top: 120px; } }

#scrollToTop {
  position: fixed;
  bottom: 20px;
  left: 20px;
  color: #F6911E;
  font-size: 3.4rem;
  z-index: 100;
  display: none; }

#speedbump {
  background: white;
  margin: 50px;
  padding: 20px;
  text-align: center; }
  #speedbump .h2 {
    font-size: 1.8rem; }
    @media only screen and (min-width: 768px) {
      #speedbump .h2 {
        font-size: 2.8rem; } }
  #speedbump p {
    max-height: 20.7rem;
    overflow: scroll; }
    @media only screen and (min-width: 768px) {
      #speedbump p {
        max-height: 1000rem;
        overflow: visible; } }

.lity-close {
  width: 50px;
  height: 50px;
  font-size: 50px; }
  .lity-close:hover, .lity-close:focus, .lity-close:active, .lity-close:visited {
    font-size: 50px; }
