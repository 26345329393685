@import 'variables';
@import 'mixins';

a {
  color: $blue__primary;
  text-decoration: underline;

  .light-text &,
  &.light-text {
    color: $white;
  }
}

h1,
.h1 {
  font-size: 4.6rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 4.0rem;
  color: $gray__dark;

  &:only-child {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .light-text &,
  &.light-text {
    color: $white;
  }
}

h2,
.h2 {
  display: block;
  font-size: 2.8rem;
  color: $blue__primary;
  text-transform: none;
  font-weight: 700;
  margin-top: 4rem;
  margin-bottom: 2rem;
  
  &:first-child {
    margin-top: 0;
  }

  &:only-child {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  
  @include lg {
    font-size: 3.0rem;
  }

  .light-text &,
  &.light-text {
    color: $white;
  }
}

h3,
.h3 {
  font-family: $sans__primary;
  font-weight: 500;
  font-size: 2.6rem;
  display: block;
  margin-top: 3rem;
  margin-bottom: 2rem;
  color: $gray__dark;

  &:first-child {
    margin-top: 0;
  }

  &:only-child {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .light-text &,
  &.light-text {
    color: $white;
  }
}

h4,
.h4 {
  font-family: $sans__primary;
  font-weight: 500;
  font-size: 2.2rem;
  margin-bottom: 1rem;
  color: $gray__dark;

  .light-text &,
  &.light-text {
    color: $white;
  }
}

p {
  font-family: $sans__secondary;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.5;
  color: $gray__dark;
  margin-bottom: 2rem;

  .small-text &,
  &.small-text {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
  }

  .light-text &,
  &.light-text {
    color: $white;
  }
}

ul,
ol {
  font-size: 1.6rem;
  margin-bottom: 2rem;
  line-height: 2rem;

  li {
    color: $gray__dark;

    .light-text & {
      color: $white;
    }
  }
}

img {
  max-width: 100%;
}

.btn {
  text-transform: uppercase;
  font-size: 1.4rem;
  padding: 1.2rem 3.4rem;
  margin-top: 1.4rem;
  border-radius: 0;
  min-width: 51%;

  @include sm {
    min-width: 0;
  }

  + .btn {

    @include sm {
      margin-left: 2.4rem;
    }
  }
}

.lity{
  background: rgba(0, 0, 0, 0.6);
}

.blue-primary{
  color: $blue__primary;
}
.blue-dark{
  color: $blue__dark;
}
.blue-light{
  color: $blue__light;
}
.blue-neon{
  color: $blue__neon;
}
.blue-lightTeal{
  color: $blue__lightTeal
}
.gray-dark{
  color: $gray__dark;
}
.gray-light{
  color: $gray__light;
}
.white{
  color: $white;
}
.yellow{
  color: $yellow;
}
.yellow-light{
  color: $yellow__light;
}
.orange{
  color: $orange;
}
.red{
  color: $red;
}
.red-dark{
  color: $red__dark;
}
.green{
  color: $green;
}
.purple{
  color: $purple;
}

.dark-background {
  background-color: $blue__dark;
}

.light-blue-background {
  background: $blue__lightTeal;

  .row.padded {
    &:last-child {
      padding-bottom: 12rem;

      @include md {
        padding-bottom: 12rem;
      }
    }

    &:first-child {
      padding-top: 5rem;

      @include md {
        padding-top: 12rem;
      }
    }
  }

  .btn-secondary {
    background: $blue__lightTeal;

    &:hover,
    &:visited {
      color: $blue__dark;
    }
  }
}

.dark-blue-text {
  color: $blue__dark;

  p,
  .p {
    color: $blue__dark
  }
}

a.btn-primary, button.btn-primary {
  color: $white;
  background: $blue__dark;
  border: 2px solid $blue__dark;

  &.yellow {
    background: $yellow;
    border: 2px solid $yellow;
    color: $blue__dark;

    &:not(:disabled):not(.disabled):hover {
      outline-color: $white;
      background-color: $yellow__light;
      border: 2px solid $yellow__light;
      color: $blue__dark;
    }

    &:not([href]):not([tabindex]){
      color: $blue__dark;
    }

    &:focus,
    &:not(:disabled):not(.disabled):active {
      outline: 3px solid $yellow;
      background: $yellow;
      border: 2px solid $blue__dark;
      color: $blue__dark;
    }

    &:disabled,
    &.disabled {
      background-color: $yellow__light;
      opacity: .65;
      border: 2px solid $yellow__light;
      color: $blue__dark;
    }
  }

  &:hover {
    background-color: $blue__primary;
    border: 2px solid $blue__primary;
  }

  &:focus,
  &:not(:disabled):not(.disabled):active {
    outline: 3px solid $blue__dark;
    background: $blue__dark;
    border: 2px solid $white;
  }

  &:disabled,
  &.disabled {
    background-color: $gray__light;
    border: 2px solid $gray__light;
    color: $blue__dark;
  }

  .light-text &,
  &.light-text {
    color: $blue__dark;
    background-color: $white;
    border: 2px solid $white;

    &.yellow {
      background: $yellow;
      border: 2px solid $yellow;
      color: $blue__dark;

      &:not(:disabled):not(.disabled):hover {
        outline-color: $yellow__light;
        background-color: $yellow__light;
        border: 2px solid $yellow__light;
        color: $blue__dark;
      }

      &:focus,
      &:not(:disabled):not(.disabled):active {
        outline: 3px solid $yellow;
        background: $yellow;
        border: 2px solid $blue__dark;
        color: $blue__dark;
      }

      &:disabled,
      &.disabled {
        background-color: $yellow__light;
        opacity: .65;
        border: 2px solid $yellow__light;
        color: $blue__dark;
      }
    }

    &:not(:disabled):not(.disabled):hover {
      color: $white;
      background-color: $blue__primary;
      border: 2px solid $blue__primary;
    }

    &:focus,
    &:not(:disabled):not(.disabled):active {
      outline: 3px solid $white;
      background: $white;
      border: 2px solid $blue__dark;
      color: $blue__dark;
    }
  }
}

.btn-secondary {
  color: $blue__primary;
  background: $white;
  border: 2px solid $blue__primary;

  &:hover {
    background-color: $blue__primary;
    border: 2px solid $blue__primary;
  }

  &:focus,
  &:not(:disabled):not(.disabled):active {
    outline: 3px solid $blue__dark;
    background: $blue__dark;
    border: 2px solid $white;
    color: $white;
  }

  &:disabled,
  &.disabled {
    background: $white;
    border: 2px solid $gray__dark;
    color: $gray__dark;
  }

  .light-text &,
  &.light-text {
    color: $white;
    background: $blue__dark;
    border: 2px solid $white;

    &:not(:disabled):not(.disabled):hover {
      color: $white;
      background-color: $blue__primary;
      border: 2px solid $blue__primary;
    }

    &:focus,
    &:not(:disabled):not(.disabled):active {
      outline: 3px solid $white;
      background: $white;
      border: 2px solid $blue__dark;
      color: $blue__dark;
    }
  }
}

.cursor-pointer:not(:disabled):not(.disabled)
{
  cursor: pointer;
}