form.cta-form{
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;

    label{
        display: block;
        width: 100%;
        font-weight: bold;

        .light-text &{
            color: white;
        }
    }

    input{
        display: block;
        width: 100%;
        background-color: #e8f0fe;
        margin-bottom: 1.6rem;
        border: none;
        height: 4rem;
        padding: 1.3rem 1.6rem;

        &[type=submit]{
            background-color: $blue__primary;
            color: $white;
            margin-top: 3rem;
            cursor: pointer;
        }
    }
}