/*-------MEDIA QUERIES-------*/

/*
 * Bootstrap has 4 breakpoints that you can use:
 *      .col-sm for larger mobile phones (devices with resolutions ≥ 576px);
 *      .col-md for tablets (≥768px);
 *      .col-lg for laptops (≥992px);
 *      .col-xl for desktops (≥1200px)
 */

//do NOT use REM units in media queries because of browser differences
@mixin sm() {
    @media only screen and (min-width: 576px) {
        @content;
    }
}

@mixin md() {
    @media only screen and (min-width: 768px) {
        @content;
    }
}
@mixin lg() {
    @media only screen and (min-width: 992px) {
        @content;
    }
}

@mixin xl() {
    @media only screen and (min-width: 1200px) {
        @content;
    }
}

@mixin at($size) {
    @media only screen and (min-width: $size + "px") {
        @content;
    }
}

@mixin before($size) {
    @media only screen and (max-width: $size + "px"){
        @content;
    }
}

/*-------A-TAG STATES-------*/

@mixin states {
    &:hover,
    &:active,
    &:focus {
        @content;
    }
}

@mixin noselect() {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
}

/*-------THEMES-------*/

@mixin theme($theme) {
    $result: '';
    @each $item in & {
        $result: $result $theme $item ',';
    }
    @at-root #{$result} {
        @content;
    }
}

@mixin sf {
    @include theme('.sfPageEditor'){
        @content;
    }
}

@mixin theme-join($theme) {
    $result: '';
    @each $item in & {
        $result: $result $theme + $item ',';
    }
    @at-root #{$result} {
        @content;
    }
}

@mixin transition {
    transition: all ease-in-out $transition-speed;
}

@mixin theme-wrap($theme, $level: 1, $separator: ' ') {
    $result: '';
    @each $item in & {
        $section: '';
        $count: 0;
        @each $thing in $item {
            $count: $count + 1;
            @if $count == $level {
              $section: $section + $separator + $theme + ' ' + $thing;
            } @else {
              $section: $section + ' ' + $thing;
            }
            @debug $section;
        }
        $result: $result $section + ',';
    }
    @at-root #{$result} {
        @content;
    }
}

//http://hackingui.com/front-end/a-pure-css-solution-for-multiline-text-truncation/
@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white){
    overflow: hidden;
    position: relative;
    line-height: $lineHeight;
    max-height: $lineHeight * $lineCount; 
    text-align: justify;
    padding-right: 1em;
    &:before {
      content: '...';
      position: absolute;
      right: 0;
      bottom: 0;
    }
    &:after {
      content: '';
      position: absolute;
      right: 0;
      width: 1em;
      height: 1em;
      margin-top: 0.2em;
      background: $bgColor;
    }
  }