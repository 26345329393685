.join-card {
    background: $white;
    padding: 3.4rem 2rem;
    border-radius: 1.1rem;
    text-align: center;
    margin: 4rem 0 8rem;

    @include md {
        margin: 5rem 0 4rem;
    }

    @include xl {
        margin: 0;
        padding: 0 4.5rem;
    }

    h2 {
        font-size: 2.4rem;
        font-weight: normal;
    }

    .btn {
        width: 100%;
    }

    .col {

        @include md {
            padding: 2rem 3.6rem;

            &:first-child {
                border-right: 1px solid $blue__primary;
            }
        }

        @include lg {
            padding: 2rem 4rem;
        }

        @include xl {
            padding: 4rem 0;

            &:first-child {
                padding-top: 3rem !important;
                border-right: 0;
                border-bottom: 3px solid $blue__primary;
            }
        }
    }
}

.home-banner .clear-card {
    @include md {
        padding-left: 4.5em;
        padding-right: 4.5rem;
    }

    @include lg {
        padding-left: 5.8em;
        padding-right: 5.8rem;
    }

    @include xl {
        padding-left: 15px;
        padding-right: 15px;
    }
}