.four-column-icons {
  text-align: center;

  [class^="col-"] {
    margin-bottom: 2.4rem;
    margin-top: 2.4rem;
  }

  .icon-placeholder {
    height: 12rem;

    margin-bottom: 2rem;

    @include lg {
      margin-bottom: 2.8rem;
    }
    
    @include lg {
      height: 13.3rem;
    }


    i {
      color: $blue__primary;
      font-size: 10rem;
    }

    i,
    img {

    }

    img {
      min-width: 10rem;
      max-height: 12rem;
      max-width: 22rem;
    }
  }

}
