@import '../mixins';

.blog-post-container {
  .blog-post {
    &__feature-image {
      margin-left: -15px;
      margin-right: -15px;

      @include md {
        margin: 0;
      }

      img {
        width: 100%;
      }
    }

    &__title {
      padding-top: 1.5rem;
      font-size: 2.4rem;
      line-height: 2.8rem;
      color: $blue__primary;
      text-transform: none;
      margin-bottom: 2rem;

      @include md {
        font-size: 3.4rem;
        line-height: 1.3;
      }

      @include lg {
        font-size: 4.8rem;
        padding-top: 2rem;
      }
    }

    &__body {
      margin-bottom: 110px;
    }

    &__information {
      margin-bottom: 30px;

      @include md {
        display: flex;
        align-items: center;
      }

      &__contributor {
        display: inline-block;
        padding-right: 3rem;
        vertical-align: middle;

        &__image {
          float: left;
          height: 6rem;
          width: 6rem;
          margin-right: 2rem;
          border-radius: 50%;
          overflow: hidden;
          display: inline-block;
          vertical-align: middle;

          img {
            width: 100%;
            top: 0;
          }
        }

        &__name {
          display: inline-block;
          padding: 0 2rem 0 0;
          margin-bottom: .2rem;
          vertical-align: middle;
          font-size: 1.5rem;
          line-height: 1.8rem;
          font-weight: bold;
          color: $blue__primary;
          margin-top: .2rem;
          min-width: 70%;

          @include md {
            font-size: 1.8rem;
            min-width: 0;
            display: flex;
            align-items: center;
          }
        }
      }

      &__date {
        @include md {
          &:before {
            content: '';
            border-right: 2px solid $blue__lightTeal;
            height: 6rem;
            display: block;
            margin-right: 3rem;
          }
        }
      }

      &__date, &__category {
        display: inline-block;
        vertical-align: middle;
        font-size: 1.2rem;
        line-height: 1.8rem;
        color: $blue__primary;
        min-width: 70%;

        @include md {
          min-width: 0;
          display: flex;
          padding: 0 2rem;

          align-items: center;
          font-size: 1.5rem;
        }
      }

      &__date {
        margin-bottom: .2rem;

        @include xl {
          &:before {
            content: '';
            border-right: 2px solid $blue__lightTeal;
            height: 6rem;
            display: block;
            margin-right: 3rem;
          }
        }
      }

      &__category {
        @include md {
          &:before {
            content: '';
            border-right: 2px solid $blue__lightTeal;
            height: 6rem;
            display: block;
            margin-right: 3rem;
          }
        }
      }

      &__view-more-tags {
        text-transform: uppercase;
      }

      &__tags {
        display: none;

        @include xl {
          display: flex;
          align-items: center;
          max-width: 330px;
          padding: 0 2rem;

          &:before {
            content: '';
            border-right: 2px solid $blue__lightTeal;
            height: 6rem;
            display: block;
            margin-right: 3rem;
          }

          &__tag {
            margin-bottom: 0!important;
            &:after {
              content: ', '
            }

            &:last-child:after {
              content: ''
            }
          }

          ul {
            padding: 0;
            margin: 0;
            list-style: none;

            li {
              display: inline-block;
              line-height: 2rem;
            }
          }
        }
      }
    }
  }
  &.financial-education-article{
    .blog-post__information{
      &__category{
        padding-left: 0;
        max-width: 20%;
        &:before{
          display: none;
        }
      }
      &__tags{
        max-width: 80%;
      }
    }
  }
}

.back-banner {
  padding: 1.5rem;

  .back-link {
    font-size: 1.8rem;
    text-transform: uppercase;
    color: white;
    text-decoration: none;

    @include md {
      font-size: 2rem;
    }

    &:active, &:link, &:visited {
      color: white
    }

    .fas {
      margin-right: .6rem
    }
  }
}

.float-left-50-md {
  @include md {
    float: left;
    max-width: 50%;
    margin-top: 2rem;
    margin-right: 1.5rem;
  }
}

.caption {
  font-style: italic;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.back-link-container {
  max-width: 1400px;
  margin: 0 auto;
}
.truncate
{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.blog-post-img
{
	object-fit: cover;
	min-height: 175px;
}
.related-post-img
{
height: 150px;
width: 100%;
object-fit: cover;
}
