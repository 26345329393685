.site-footer {
    background: $blue__primary;
    color: $white;
    padding: 1rem 4rem 1rem; 

    @include md {
        display: flex;
    }

    @include at(2000) {
        padding-left: 8rem;
        padding-right: 8rem;
    }
}

.site-footer,
.site-subfooter {
    font-size: 1.4rem;
 
    @include md {
        font-size: 1.6rem;
    }

    a {
        color: $white;

        &:focus{
            outline-color: white;
        }
    }
}

.site-footer {
    &__links {

        @include md {
            order: 3;
        }

        @include at(2120) {
            -ms-flex: 0 0 91.666667%;
            flex: 0 0 91.666667%;
            max-width: 91.666667%;
        }

        ul {
            padding-left: 0
        }

        >ul {

            >li {
                font-size: 1.7rem;
                font-weight: 700;

                &:nth-child(2n) {
                    padding-right: 0;

                    @include md {
                        padding-right: 15px;
                    }
                }

                &:nth-child(2n + 1) {
                    padding-left: 0;

                    @include md {
                        padding-left: 15px;
                    }
                }

                ul {
                    margin-top: 2.4rem;
                    margin-bottom: 4.4rem;

                    li {
                        font-weight: 500;
                        font-size: 1.4rem;
                        margin-bottom: 1.4rem;
                    }
                }
            }
        }

        li {
            display: block;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
    }

    &__logo {
        display: block;
        max-width: 21.4rem;
        margin-top: 4rem;
        margin-bottom: 4.5rem;
        padding: 0;

        @include md {
            order: 1;
            max-width: none;
            margin-top: 2.5rem;
            padding: 0 15px;
        }

        @include at(1600) {
            max-width: 75%; 
        }

        img {
            max-width: 100%;

            @include md {
                max-width: 24.2rem;
            }
        }
    }

    &__contact {

        @include md {
            order: 10;
            width:100%;

           > div
           { 
               display: flex;
               justify-content: center;
               > p
                {
                    margin-top:15px;
                        display:flex;
                        flex-flow: row wrap;

                        span{
                            margin-right:15px;
                        }
                }
            }
        }

        &__telephone,
        &__email {
            margin-bottom: 1.2rem;
        }
    }

    &__social-nav {
        margin-top: 2.5rem;
        padding: 0;

        @include md {
            order: 4;
            margin-top: 0;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }

        @include at(2120) {
            -ms-flex: 0 0 8.333333%;
            flex: 0 0 8.333333%;
            max-width: 8.333333%;
        }

        ul {
            padding: 0;

            li {
                display: inline-block;
                padding-right: 1.5rem;
                
                @include md {
                    padding-right: 2.5rem;
                }

                @include md {
                    display: block;
                    margin-top: 1rem;
                    margin-bottom: 1rem;
                }

                i {
                    font-size: 1.8rem;
                    width: 20px;
                    text-align: center;
                    @include md {
                        margin-right: .8rem;
                    }
                }
            }
        }
    }
    &__legal-images{
        order: 5;
        width: 100%;
        border-top: 1px solid white;
        padding-top: 3rem;
        text-align: center;

        @include md{
            border: none;
            padding-top: 0;
        }

        .legal-image{
            margin-right: 3rem;
            display: inline-block;
            padding: 4px;

            img{
                height: 3rem;

                @include md{
                    height: 4rem;
                }
            }
        }

        &__text{
            font-size: 1.4rem;
            line-height: 1.8rem;
            margin-top: 3rem;
            display: inline-block;
        }
    }
}

.site-footer__newsletter-form {
    display: none;

    @include md {
        display: block;
        order: 2;
        margin-top: 2.5rem;
    }

    .form-group .inputs {
        @include md {
            display: flex;
            // justify-content: flex-end;
        }
    }

    input {
        padding: .8rem 1.1rem;
        height: 4rem;
        width: 22rem;
        border-radius: 0;
        border: 0;

        @include at(980) {
            width: 22rem;
        }

        @include at(2000) {
            width: 28.6rem;
        }
    }

    label {
        display: block;
        margin-top: 1.2rem;
        font-size: 1.4rem;
    }

    button {
        height: 4rem;
        display: inline-block;
        border: none;
        margin: 0;
        text-decoration: none;
        background: $blue__dark;
        color: $white;
        font-family: $sans__primary;
        cursor: pointer;
        text-align: center;
        transition: background 250ms ease-in-out,
            transform 150ms ease;
        -webkit-appearance: none;
        -moz-appearance: none;

        @include md {
            padding: 1rem 3.3rem;
        }

        @include at(980) {
            padding: 1rem 3.8rem;
        }

        @include at(2000) {
            padding: 1rem 5.5rem;

        }
    }

    button:hover,
    button:focus {
        background: #0053ba;
    }

    button:focus {
        outline: 1px solid #fff;
        outline-offset: -4px;
    }

    button:active {
        transform: scale(0.99);
    }
}

.site-subfooter {
    background: $blue__dark;
    padding: 2.2rem 1.6rem 1rem;
    color: $white;
    font-size: 1.3rem;
    line-height: 2;

    @include md {
        display: flex;
        justify-content: space-between;
    }

    ul {
        padding: 0;
        text-align: center;
        font-size: 1.3rem;

        li {
            display: inline-block;
        }
    }

    &__legal {
        ul li {
            &:last-child:after {
                content: '';
            }

            &:after {
                content: '|';
                padding-left: .3rem;
                padding-right: .3rem;
            }
        }
    }

    &__helpful-links {
        display: none;

        @include md {
            display: block;
        }

        ul li {
            padding-left: 1.2rem;
            padding-right: 1.2rem;
        }
    }
}