
.recent-blog-posts.rondell-carousel{
    visibility: hidden;
    &.rondell-container{
        visibility: visible;
        min-height: 35rem;
        overflow: initial;
        margin-top: 3.5rem;
        @include at(425){
            min-height: 45rem;
        }
        @include xl{
            margin-top: 0;
        }
        &:not(.no-perspective) { 
            perspective: 1500px;
            perspective-origin: top; 
        }
        &:not(.no-transform) .tilt-left { 
            transform: rotate3d(0.1, -0.3, 0, 15deg); 
        }
        &:not(.no-transform) .tilt-right { 
            transform: rotate3d(0.1, 0.3, 0, 15deg); 
        }
        
            
        .recentBlogPost{
            display: block;
            background-color: $blue__light;
            color: $blue__dark;
            width: 25rem;
            height: 28rem;
            padding: 1rem;
            text-decoration: none;
            cursor: pointer;
    
            @include at(425){
                width: 36rem;
                height: 40rem;
                padding: 2.5rem;
            }
            &.rondell-item-focused{
                .recentBlogPost__image{
                    height: 12rem;
                    @include at(425){
                        height: 17rem;
                    }
                }
                .recentBlogPost__heading__text{
                    @include multiLineEllipsis($lineHeight: 2.1rem, $lineCount: 3, $bgColor: $blue__light);
                    @include at(425){
                        @include multiLineEllipsis($lineHeight: 3rem, $lineCount: 3, $bgColor: $blue__light);
                    }
                }
            }
    
            &.tilt-left{
                .recentBlogPost__contributor, .recentBlogPost__heading, .recentBlogPost__readMore{
                    display: none;
                }
                .recentBlogPost__image {
                    height: 26rem;
                    margin-left: -20rem;
                }
                .recentBlogPost__seeBlog{
                    font-size: 3rem;
                    line-height: 4rem;
                    margin: 1rem 0;
                    height: 26rem;
                }
            }
            &.tilt-right{
                .recentBlogPost__contributor, .recentBlogPost__heading, .recentBlogPost__readMore{
                    display: none;
                }
                .recentBlogPost__image {
                    height: 26rem;
                    margin-left: -20rem;
                }
                .recentBlogPost__seeBlog{
                    font-size: 3rem;
                    line-height: 4rem;
                    margin: 1rem 0;
                    height: 26rem;
                }
            }
    
            &__image{
                height: 12rem;
                background-size: cover;
                margin: -1rem;
                margin-bottom: 1rem;
                @include at(425){
                    height: 17rem;
                    margin: -2.5rem;
                    margin-bottom: 2.5rem;
                }
            }
            &__contributor{
                font-size: 1.8rem;
                line-height: 2.6rem;
                text-align: center;
                font-weight: bold;
                margin: 1rem 0;
                @include at(425){
                    margin: 2rem 0;
                    font-size: 2.1rem;
                    line-height: 2.9rem;
                }
            }
            &__heading{
                font-size: 1.6rem;
                line-height: 2.1rem;
                text-align: center;
                margin: 1rem 0;
                height: 7.8rem;
                &__text{
                    @include multiLineEllipsis($lineHeight: 2.1rem, $lineCount: 3, $bgColor: $blue__light);
                }
                @include at(425){
                    font-size: 2rem;
                    line-height: 3rem;
                    height: 8.8rem;
                    &__text{
                        @include multiLineEllipsis($lineHeight: 3rem, $lineCount: 3, $bgColor: $blue__light);
                    }
                }
            }
            &__readMore{
                font-size: 1.4rem;
                line-height: 1.7rem;
                text-align: right;
                font-weight: 500;
                text-decoration: underline;
            }
            &__seeBlog{
                font-size: 3rem;
                line-height: 4rem;
                text-align: left;
                margin: 1rem 0;
                height: 21.8rem;
                @include at(425){
                    font-size: 4rem;
                    line-height: 6rem;
                    text-align: left;
                    margin: 2rem 0;
                    height: 28.8rem;
                }
            }
        }
        a.rondell-control{
            width: 32px;
            height: 35px;
            opacity: 1;
            background-repeat: no-repeat;
            background-position: center;
            box-shadow: none;
            &:hover{
                box-shadow: none;
            }
            &.rondell-shift-left {
                background-image: url(../img/icons/arrowLeftBlue.png);
            }
            &.rondell-shift-right {
                background-image: url(../img/icons/arrowRightBlue.png);
            }
        }
    }
}
