@import 'variables';
@import 'mixins';

@import 'header';
@import 'footer';
@import 'basics';

@import 'layouts/index';
@import 'cards';
@import 'tables';
@import 'forms';

@import 'search-results';
@import 'search-bar';
@import 'faq';
@import 'blog/blog-listing';
@import 'blog/blog-detail';
@import 'comparison-widget';
@import 'team-listing';

@import 'home/login-apply';
@import 'home/alert-banner';

@import 'sitefinity';

:root {
    font-size: 62.5%;
}

body {
    font-family: $sans__primary;
    margin: 0;
    font-size: 1.4rem;
    line-height: 1.5rem;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

i.fas {
    padding: 0 .4rem;
}

#mainContent {
    li {
        margin-bottom: 1.5rem;
        line-height: 2.3rem;
    }

    @include xl {
        margin-top: 120px;
    }
}

#scrollToTop {
    position: fixed;
    bottom: 20px;
    left: 20px;
    color: #F6911E;
    font-size: 3.4rem;
    z-index: 100;
    display: none;
}

#speedbump{
    background: white;
    margin: 50px;
    padding: 20px;
    text-align: center;

    .h2{
        font-size: 1.8rem;

        @include md{
            font-size: 2.8rem;
        }
    }

    p{
        max-height: 20.7rem;
        overflow: scroll;

        @include md{
            max-height: 1000rem;
            overflow: visible;
        }
    }
}

.lity-close{
    width: 50px;
    height: 50px;
    font-size: 50px;
    &:hover, &:focus, &:active, &:visited{
        font-size: 50px;
    }
}
