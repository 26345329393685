.two-column-cta{
    .col-md-6{
        &:last-child{
            justify-content: flex-end;
            display: flex;
        }

        p{
            margin: 0;
            align-self: center;
        }

        .btn{
            margin-top: 0;
            align-self: center;
        }
    }
}