.tab-sections .tab-section, .tab-sections .sf_cols{
    display: none;
    &:first-child{
        display: block;
    }
}
.tabbed-navigation ul{
    margin-bottom: 0;
    padding: 0;
}
.rates-terms{
    h2{
        display: inline-block;
    }

    .last-updated{
        font-size: 1.6rem;
        margin-top: -0.5rem;
        margin-bottom: 2rem;

        @include md{
            float: right;
            margin-top: 1.5rem;
            margin-bottom: 0;
        }

        .light-text &{
            color: $white;
        }
    }
}

table{
    width: 100%;
    margin-bottom: 3rem;

    .light-text &{
        color: $white;
    }

    thead{
        left: -9999rem;
        position: absolute;
        top: -9999rem;

        @include md{
            left: 0;
            position: relative;
            top: 0;

            th{
                font-size: 1.4rem;
                font-weight: bold;
                text-align: center;
                display: table-cell;
                padding: 2rem 2.6rem;
                margin: 0;
                text-transform: uppercase;
                &:first-child{
                    text-align: left;
                }
            }
        }
    }

    tbody{

        td{
            display: block;
            font-size: 1.6rem;
            line-height: 1.8rem;
            margin: 2rem 2.6rem;
            text-align: left;
            font-weight: bold;
    
            @include md{
                display: table-cell;
                padding: 2rem 2.6rem;
                text-align: center;
                margin: 0;
                font-weight: normal;
            }
    
            &:first-child{
                text-align: left;
                font-weight: bold;
            }
    
            &:before{
                content: attr(name) ": ";
                display: inline-block;
                font-size: 1.6rem;
                font-weight: 300;
                margin-right: .5rem;
    
                @include md{
                    content: none;
                }
            }
        }

        tr:nth-of-type(odd){
            background: #e2eaf2;

            .dark-background &{
                background: rgba(56, 113, 170, .5);
            }
        }
    }
    
}