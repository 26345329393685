.hbs {
    //.header__bottom__search
    display: inline-block;


    @include xl {
        height: 90px;
    }

    &__input {
        width: 0;
        border: 0;
        overflow: hidden;
        transition: .5s ease all;
        color: $blue__primary;
    }

    &__activate {
        background-color: transparent;
        border: 0;
        color: $blue__primary;
        cursor: pointer;
        margin: 0;

        @media only screen and (max-width: 992px) {
            padding-right: 4px;
            padding-top: 4px;
        }

        @include xl {
            margin: 30px 24px 0 0;
        }

        &__icon {
            font-size: 2.2rem;
            line-height: 2.6rem;

            @include md {
                font-size: 2.4rem;
                line-height: 2.9rem;
                padding-right: 1rem;
            }


            @include xl {
                font-size: 1.8rem;
                padding-top: 0;
            }
        }

        &__text {
            display: none;
            width: 63px;
            transition: .5s ease all;

            @include xl {
                display: inline-block;
            }
        }
    }

    &__close {
        background: transparent;
        border: none;
        color: $blue__primary;
        cursor: pointer;
        font-size: 2.1rem;
        margin-right: -5%;
        display: none;

        @media only screen and (max-width: 767px) {
            padding: 0;
        }
    }


    &__typeahead {
        position: absolute;
        background: white;
        list-style: none;
        padding-left: 0;
        padding-top: 5px;
        padding-bottom: 5px;
        text-align: left;
        min-width: 257px;
        box-shadow: 0 7px 10px 0 rgba(0,0,0,.25);
        z-index: 300;
        
        li {
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 12px;
            cursor: pointer;

            &.selected, &:hover {
                background: $blue__dark;
                color: $white;
            }
        }
    }
}