.one-column-icons {

    .content-placeholder,
    .icon-placeholder {
        display: block;

        @include md {
            display: inline-block;
            vertical-align: top;
        }
    }

    .content-placeholder {
        @include md {
            width: calc(100% - 5rem);
            padding-left: 1rem;
        }

        @include lg {
            padding-left: 2rem;
        }
    }

    .icon-placeholder {
        text-align: center;
        width: 100%;
        margin-top: 4rem;
        margin-bottom: 0;

        @include md {
            width: auto;
            max-width: 4.4rem;
            margin-top: 0;
        }

        i {
            color: $blue__primary;
            font-size: 10rem;

            @include md {
                font-size: 4rem;
                float: right;
            }
        }

        i,
        img {
            vertical-align: top;
      
        }

        img {
            min-width: 10rem;
            min-height: 16rem;
            max-width: 22rem;

            @include md {
                min-width: 5rem;
                min-height: 0;
                max-width: 5.5rem;
                max-height: 6rem;
                float: right;
            }
        }
    }
}