.faqFilters__button {
  width: 100%;
  border-radius: 2.5rem;


  @include md {
    margin-top: 0;
  }
}

.faq {
  line-height: 1.6;

  &.card{
    border-color: rgba(33, 96, 169, .3);
  }

  &:first-child {
    margin-top: 2rem;
  }

  &__question {
    font-size: 1.8rem;
    color: $blue__primary;

    &.card-header{
      background-color: $blue__light;
      border-bottom-color: rgba(33, 96, 169, .3);
    }

    .btn.btn-link{
      padding: 0;
      margin: 0;
      font-size: 1.8rem;
      color: $blue__primary;
      text-transform: none;
      white-space: normal;
      text-align: left;
    }
  }

  &__answer {
    font-size: 1.4rem;
    line-height: 2;


    &:before {
      content: ''
    }

    em {
      font-weight: 700;
      font-style: normal;
    }
  }
}

.faq-container .row.padded {
  padding-top: 4rem;
}

.faqListing {
  margin-top: 2rem;

  @include md {
    margin-top: 0;
  }
}

.paging {
  list-style: none;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-top: 6rem;
  margin-bottom: 12rem;

  @include md {
    margin-top: 9rem;
    margin-bottom: 16rem;
  }

  @include xl {
    margin-top: 12rem;
    margin-bottom: 18rem;
  }

  li {
    display: block;
    height: 4.4rem;
    width: 4.4rem;
    border-radius: .8rem;
    text-align: center;
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }

    a {
      display: block;
      line-height: 4.4rem;
      text-decoration: none;
    }
  }

  &__num,
  &__prev,
  &__next,
  &__elipses {
    cursor: pointer;
    @include noselect();
  }

  &__num,
  &__elipses {

    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .5);
  }

  &__num {
    &.active {
      background: $blue__primary;
      color: $white;

      a {

        &:link,
        &:active,
        &:visited {
          color: $white;
        }
      }
    }
  }
}

