.l-fifty-fifty>.col-md-6 {
    margin-bottom: 1rem;

    &:first-child {
        margin-top: 1rem;
    }

    &.image {
        order: 1
    }

    &.text {
        order: 2
    }

    @include md {

        &.image,
        &.text {
            order: unset;
        }
    }

    @include lg {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        margin-bottom: 0;

        &:first-child {
            margin-top: 0;
            padding-left: 1.5rem;
        }

        &:last-child {
            padding-right: 1.5rem;
        }
    }

    @include xl {
        padding-left: 3.5rem;
        padding-right: 3.5rem;
    }
}