 .product-comparison-widget {
     width: 100%;

     @include xl {
         position: relative;
         padding-left: 228px;
     }

     ul {
         list-style: none;
         padding: 0;

         &.feature-labels {
             display: none;

             @include xl {
                 display: inline-block;
                 margin-left: -228px;
                 width: 20%;
                 position: absolute;
                 top: 188px;
             }

             .feature-label {
                 height: 40px;
                 border-bottom: 1px solid $blue__lightTeal;
                 color: #2160a9;
                 font-size: 1.4rem;
                 font-weight: 700;
                 margin: 0;
                 display: flex;
                 align-items: center;
                 text-align: right;
                 flex-direction: row-reverse;

                 &:first-child {
                     border-top: 1px solid $blue__lightTeal;
                 }
             }
         }

         &.product-cards {
             padding: 15px;
             margin-bottom: 10px;

             @include xl {
                 width: 100%;
                 display: inline-block;
             }

             .product-card {
                 border: 1px solid #E1E1E1;
                 border-radius: 10px;
                 padding: 35px;
                 box-shadow: rgba(0, 65, 106, 0.33) 0 3px 6px;
                 position: relative;
                 width: 98%;
                 left: 3px;

                 @include md {
                     width: 100%;
                     left: 0;
                 }

                 @include xl {
                     display: inline-block;
                     margin: 0 1px;
                     padding: 15px;
                 }

                 .title {
                     font-size: 2.8rem;
                     color: $blue__primary;
                     text-align: center;
                     line-height: 3.5rem;
                     font-weight: bold;
                     border-bottom: 1px solid $blue__lightTeal;
                     padding: 2.6rem 5px;
                     margin-bottom: 3.2rem;

                     @include xl {
                         font-size: 1.8rem;
                         line-height: 3rem;
                         margin: 0 -15px;
                         min-height: 158px;
                         display: flex;
                         align-items: center;

                         .text {
                             display: block;
                             margin: 0 auto;
                         }
                     }
                 }

                 .more-information {
                     background-color: $blue__lightTeal;
                     border: none;
                     border-radius: 50%;
                     color: $blue__primary;
                     height: 3rem;
                     width: 3rem;
                     position: absolute;
                     top: 2rem;
                     right: 2rem;
                     padding: .1rem;

                     @include xl {
                         height: 2rem;
                         width: 2rem;
                         top: 1rem;
                         right: 1rem;
                     }

                     i {
                         font-size: 1.6rem;
                         padding: 0;

                         @include xl {
                             font-size: 1rem;
                             padding-bottom: 3px;
                         }
                     }
                 }

                 ul.features {

                     li.feature {
                         font-size: 1.8rem;
                         font-weight: bold;
                         text-align: center;
                         line-height: 1.8rem;
                         color: $blue__primary;
                         margin-bottom: 2rem;

                         @include xl {
                             height: 40px;
                             border-bottom: 1px solid $blue__lightTeal;
                             margin: 0 -15px;
                             padding: 10px 0;
                         }

                         &.notFeatured {
                             color: rgba(0, 65, 106, .7);
                             font-weight: normal;
                             text-decoration: line-through;

                             .offered {
                                 display: none;
                             }
                         }

                         &.hasFeature {
                             .not-offered {
                                 display: none;
                             }

                             i:before {
                                 display: none;

                                 @include xl {
                                     display: inline;
                                 }
                             }
                         }

                         .feature-label {
                             @include xl {
                                 display: none;
                             }
                         }
                     }
                 }

                 a.view-details {
                     padding: 2rem;
                     display: block;
                     margin: 0 -36px -36px;
                     height: 60px;
                     border-radius: 0 0 10px 10px;
                     font-size: 1.8rem;
                     font-weight: bold;
                     line-height: 1.8rem;
                     text-decoration: none;

                     @include xl {
                         font-size: 1.4rem;
                         padding: 2rem 0;
                         margin: 0 -15px -15px;
                     }
                 }
             }

             .owl-item:last-child .product-card {
                 @include xl {
                     width: 97%;
                 }
             }

             .owl-nav {
                 display: none;
             }
         }
     }
 }

 .owl-dots {
     margin-top: 10px;

     @include lg {
         display: none;
     }
 }

 .owl-dots .owl-dot {
     display: inline-block;
     zoom: 1;
     background-color: $white;
     border: 0;
 }

 .owl-dots .owl-dot>span {
     width: 10px;
     height: 10px;
     margin: 5px 7px;
     background: #D6D6D6;
     display: block;
     -webkit-backface-visibility: visible;
     transition: opacity .2s ease;
     border-radius: 30px;

     span.sr-only {
         color: black;
         background: $white;
     }
 }

 .owl-dots .owl-dot.active span,
 .owl-dots .owl-dot:hover span {
     background: #869791;
 }

 .owl-dots {
     margin-top: 40px;
     text-align: center;

     @include xl {
         margin-top: 10px;
     }

     .owl-dot {
         display: inline-block;

         span {
             display: inline-block;
             background-color: $blue__lightTeal;
             height: 20px;
             width: 20px;
             border: 1px solid $blue__primary;
             border-radius: 50%;
         }

         &.active span {
             background-color: $blue__primary;
         }
     }
 }

 //Note: this will affect all tooltips across the site. If in the future we need to 
 //have different tooltip styles, you can do so by adding a custom HTML wrapper
 //to the tooltip title with a specific class, add "data-html='true'", and then target
 //the classes separately. See https://getbootstrap.com/docs/4.0/components/tooltips/
 .tooltip {
     &.show {
         opacity: 1;
     }

     .tooltip-inner {
         background-color: $yellow;
         color: $blue__dark;
         font-size: 1.6rem !important;
     }

     &.bs-tooltip-bottom .arrow:before {
         border-bottom-color: $yellow !important;
     }
 }

 .pcw__arrow-nav {
     display: none;

     @include lg {
         display: block;
         margin: 0 auto;
         color: $blue__primary;
         width: 15%;
         font-size: 2.5rem;

        .fas.disabled {
            color: $gray__light;
        }

         .fa-arrow-left {
             float: left;
         }

         .fa-arrow-right {
             float: right;
         }
     }
 }