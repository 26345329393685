.one-fifth{
    width: 100%;
    margin-bottom: 8rem;
    @include md{
        flex: 0 0 33.3333%;
        max-width: 33.3333%;
        text-align: left;
        margin-bottom: 4rem;
    }
    @include xl{
        flex: 0 0 22%;
        max-width: 22%;
    }
}
.two-fifths{
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    @include xl{
        flex: 0 0 34%;
        max-width: 34%;
    }
    &>div{
        align-self: center;
    }
}
.current-rates-widget {
    text-align: center;

    .rate-icon {
        width: 100%;
        height: 150px;
        display: flex;
        justify-content: center;

        img {
            align-self: flex-end;
            max-height: 100%;
            max-width: 170px;
            width: 100%;
            height: 100%;
        }
    }

    .rate-subtitle {
        font-size: 1.8rem;
        color: $blue__primary;
        font-weight: 900;
        margin: 2rem 0 1rem;
    }

    .rate {
        font-size: 5.8rem;
        color: $blue__primary;
        font-weight: 900;
        line-height: 5rem;
    }

    .btn {
        @include md {
            padding: 1.1rem 1rem;
            font-size: 1.3rem;
            width: 100%;
        }
    }
}
