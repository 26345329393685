.row.white-card {
    flex-direction: row-reverse;

    [class^="col"] {
        background: $white;
        padding: 5rem 3.8rem;
        box-shadow: 0px 2px 35px 0px rgba(0, 0, 0, 0.75);

        @include xl {
            padding: 6.2rem 4.6rem;
        }

        @include before(576) {
            background: none;
            color: $white;
            box-shadow: none;

            h1,
            .h1,
            h2,
            .h2,
            h3,
            .h3,
            p,
            .p,
            ul,
            a:active,
            a:visited,
            a:link,
            a {
                color: $white;

                &.btn-primary {
                    color: $blue__dark;
                }
            }

            .btn {
                min-width: unset;
                margin-right: 24px;
            }

            .btn-primary {
                background-color: $white;
                border: 2px solid $white;
                color: $blue__dark;

                &:not(:disabled):not(.disabled):hover {
                    color: $white;
                    background-color: $blue__primary;
                    border: 2px solid $blue__primary;
                }

                &:focus,
                &:not(:disabled):not(.disabled):active {
                    outline: 3px solid $white;
                    background: $white;
                    border: 2px solid $blue__dark;
                    color: $blue__dark;
                }
            }

            .btn-secondary {
                color: $white;
                background: $blue__dark;
                border: 2px solid $white;

                &:not(:disabled):not(.disabled):hover {
                    color: $white;
                    background-color: $blue__primary;
                    border: 2px solid $blue__primary;
                }

                &:focus,
                &:not(:disabled):not(.disabled):active {
                    outline: 3px solid $white;
                    background: $white;
                    border: 2px solid $blue__dark;
                    color: $blue__dark;
                }
            }
        }
    }
}

.clear-card {

    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    p,
    .p,
    ul,
    a:active,
    a:visited,
    a:link,
    a {
        color: $white;
    }

    a:active,
    a:visited,
    a:link,
    a {
        text-decoration: underline;
    }

}
.fined-card {
    background: #ffffff;
    padding: 3.4rem 2rem;
    border-radius: 1.1rem;
    text-align: center;
    margin: 4rem 0 8rem; }
    @media only screen and (min-width: 768px) {
    .fined-card {
    margin: 5rem 0 4rem; } }
    @media only screen and (min-width: 1200px) {
    .fined-card {
    margin: 0;
    padding: 0 4.5rem; } }
    .fined-card h2 {
    font-size: 2.4rem;
    font-weight: normal; }
    .fined-card .btn {
    width: 100%; }
    @media only screen and (min-width: 768px) {
    .fined-card .col {
    padding: 2rem 3.6rem; }
    }
    @media only screen and (min-width: 992px) {
    .fined-card .col {
    padding: 2rem 4rem; }
    }
    @media only screen and (min-width: 1200px) {
    .fined-card .col {
    padding: 4rem 0; }
    }